export interface FinancialClassEntity {
  Id: string;
  Description: string;
  IsActive: boolean;
  ViewId?: string;
  Version?: string;
  UseDischargeDateAsKeyForInpatient: boolean;
  UseDischargeDateAsKeyForOutpatient: boolean;
}

export interface FinancialClassResponse {
  data: FinancialClassEntity[];
  totalItems: number;
  page: number;
  pageSize: number;
  focusedFinancialClassViewId?; string;
}

export interface CardEntity {
  ViewId?: string;
  IsInpatientInstance: boolean;
  EffectiveDate: string;
  ExpirationDate: string;
  PrimarySubInstanceViewId: string | null;
  Version?: string;
}

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
export interface IPCardEntity extends CardEntity{
  DxCodesToResequence: number;
  PrCodesToResequence: number;
  CostOutlierPayments: boolean;
}

export interface OPCardEntity extends CardEntity{
  OceVersion: string;
}

export interface ChoiceListItem {
  id: string;
  text: string;
  tccId?: string;
}

export interface OptionsChoiceListItem extends ChoiceListItem {
  itemText: string;
}

export const SETTINGS_TYPE_IP = 'IP';
export const SETTINGS_TYPE_OP = 'OP';

export const COMPARISON_CHOICES: ChoiceListItem[] = [
  { id: '1', text: 'Admit Date' },
  { id: '2', text: 'Discharge Date' }
];

export const COMPARISON_CHOICES_ADMIT_DATE_ID = '1';
export const COMPARISON_CHOICES_DISCHARGE_DATE_ID = '2';

export const OPCARD_VERSIONS: ChoiceListItem[] = [
  { id: 'Opps', text: 'OPPS'},
  { id: 'NonOpps', text: 'Non-OPPS' }
];

export const findOPCardVersion = (version: number | string) => {
  const found = OPCARD_VERSIONS.find((opVersion) => opVersion.id === version);

  return found;
}

export interface GrouperCardEntity {
  ViewId?: string;
  GrouperType: string;
  GrouperVersion: string;
  PricerType: string;
  SimplePricing: boolean;
  GrouperOptions?: string;
  DefaultGrouperOptions?: string;

  // additional fields
  PricerDescription?: string;
  AllowFullPricing?: boolean;
  AllowSimplePricing?: boolean;
  AvailableGrouperOptions?: string[];
  HasFieldsForFullPricing?: boolean;
  HasFieldsForSimplePricing?: boolean;
  AllowCustomDrgWeights?: boolean;
  IsCustomDrgWeightsAvailable?: boolean;

  Version?: string;
}

export interface GroupersChoiceListItem {
  Id: string;
  Description: string;
  DefaultGrouperVersion?: string;
}

export interface VersionsChoiceListItem {
  Version: string;
}

export interface PricersChoiceListItem {
  Id: string;
  Description: string;
  AllowSimplePricing: boolean;
  AllowFullPricing: boolean;
  EffectiveDate?: string | null;
  ExpirationDate?: string | null;
  AvailableGrouperOptions?: string[];
  DefaultGrouperOptions: string;
}

export interface PricerCardFieldDefaultValue {
  Description: string;
  Value: string;
}

export interface PricerCardField {
  Id: string;
  Description: string;
  LongDescription: string;
  Regex: string | null;
  RangeStart: number | null;
  RangeEnd: number | null;
  SimpleVisible: boolean;
  FullVisible: boolean;
  AllowUserDefinedValue: boolean;
  HasDefaultValues: boolean;
  DefaultValues: PricerCardFieldDefaultValue[] | null;
}

export interface PricerCardValue {
  ViewId?: string;
  PricerFieldId: string;
  Value: string;

  Version?: string;
}

// value choice list
export interface PricerCardFieldValueItem {
  Value: string;
  Description: string;
  EffectiveDate: string;
  ExpirationDate: string;
  SpecialKey?: string;    // we can have equal Values and should separate between them
}

export interface PricerCardRow extends PricerCardField {
  value?: string;
  initialValue?: string;
  list?: PricerCardFieldValueItem[];
  inEdit?: boolean;
  error?: string;

  Version?: string;
  ViewId?: string;
}

export interface PricerCardEntity {
  values: PricerCardRow[];
}

export const NONE_PRICER_ID = 'None';

// groupers that must be shown in the list once
export const NOT_REPEATABLE_GROUPERS = [
  'EAPG'
]
