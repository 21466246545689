import { ChoiceListItem } from '../scenes/FinancialClass/models/financialClass';

export enum EncounterType {
  ALL = 'All',
  INPATIENT = 'Inpatient',
  OUTPATIENT = 'Outpatient',
  SHARED = 'Shared (Inpatient/Outpatient)'
}

export enum PatientEncounterType {
  IP = 'IP',  // 'Inpatient',
  OP = 'OP',  // 'Outpatient',
  ER = 'ER',  // 'Emergency Room',
  SDS = 'SDS' // 'Same Day Surgery'
}

export const ENCOUNTER_TYPES_DDL: ChoiceListItem[] = [
  { id: "All", text: 'All', tccId: EncounterType.ALL },
  { id: "Shared", text: 'Shared (Inpatient/Outpatient)', tccId: '*' },
  { id: "Inpatient", text: 'Inpatient', tccId: PatientEncounterType.IP },
  { id: "Outpatient", text: 'Outpatient', tccId: PatientEncounterType.OP }
];
