import './Icon.less';

import * as React from 'react';
import Badge, { BadgeProps } from '@material-ui/core/Badge';

export enum IconType {
  Alert,
  AlertNew,
  ApplyMark,
  Bars,
  BookCodebooks,
  BookReferences,
  Building,
  CancelMark,
  CheckCircle,
  ChevronDown,
  ChevronRight,
  CircleNotch,
  CircleNotchSpin,
  CodingAdvice,
  Debug,
  Dots,
  Envelope,
  ExternalLink,
  Error,
  ErrorAlt,
  FileFromTemplate,
  FilePlus,
  Help,
  HelpAlt,
  History,
  Home,
  Info,
  InfoAlt,
  Logout,
  LongArrowUp,
  Pencil,
  Plus,
  PlusCircle,
  POAExempt,
  Search,
  Settings,
  SolidCircle,
  SolidSquare,
  SolidTriangle,
  Trash,
  User,
  UserPlus,
  Users,
  UserSlash,
  Video,
  Warning,
  Wrench,
}

interface IconProps {
  type?: IconType;
  className?: string;
  badgeCounter?: number;
  title?: string;
  forceTooltip?: boolean;
  setRef?: (ref) => void;
}

export const VerticalDotsClass = 'far fa-ellipsis-v';

export class Icon extends React.PureComponent<IconProps> {
  setRef = (ref) => {
    if (this.props.setRef) {
      this.props.setRef(ref);
    }
  }

  render() {
    let classes = this.props.className ? ['fa-fw icon', this.props.className] : ['fa-fw icon'];
    if (this.props.title || this.props.forceTooltip) { classes.push('with-tooltip'); }
    switch (this.props.type) {
      case IconType.ApplyMark:
        classes = classes.concat(['fal', 'fa-check']);
        break;
      case IconType.CancelMark:
        classes = classes.concat(['fal', 'fa-times']);
        break;
      case IconType.CheckCircle:
        classes = classes.concat(['fas', 'fa-check-circle']);
        break;
      case IconType.CodingAdvice:
        classes = classes.concat(['fal', 'fa-copyright']);
        break;
      case IconType.CircleNotch:
        classes = classes.concat(['fal', 'fa-circle-notch']);
        break;
      case IconType.CircleNotchSpin:
        classes = classes.concat(['fal', 'fa-circle-notch', 'fa-spin']);
        break;
      case IconType.Dots:
        classes = classes.concat(['fal', 'fa-ellipsis-h']);
        break;
      case IconType.Envelope:
        classes = classes.concat(['fas', 'fa-envelope']);
        break;
      case IconType.Error:
        classes = classes.concat(['error-icon', 'fas', 'fa-times-circle']);
        break;
      case IconType.ErrorAlt:
        classes = classes.concat(['fal', 'fa-times-square']);
        break;
      case IconType.FilePlus:
        classes = classes.concat(['fal', 'fa-file-plus']);
        break;
      case IconType.FileFromTemplate:
        classes = classes.concat(['fal', 'fa-files-medical']);
        break;
      case IconType.Home:
        classes = classes.concat(['fal', 'fa-home']);
        break;
      case IconType.Help:
        classes = classes.concat(['fal', 'fa-question-circle']);
        break;
      case IconType.HelpAlt:
        classes = classes.concat(['fal', 'fa-question']);
        break;
      case IconType.Info:
        classes = classes.concat(['fal', 'fa-info-circle']);
        break;
      case IconType.InfoAlt:
        classes = classes.concat(['fal', 'fa-info']);
        break;
      case IconType.LongArrowUp:
        classes = classes.concat(['fal', 'fa-long-arrow-up']);
        break;
      case IconType.Pencil:
        classes = classes.concat(['fal', 'fa-pencil']);
        break;
      case IconType.Plus:
        classes = classes.concat(['far', 'fa-plus']);
        break;
      case IconType.PlusCircle:
        classes = classes.concat(['fal', 'fa-plus-circle']);
        break;
      case IconType.Search:
        classes = classes.concat(['fal', 'fa-search']);
        break;
      case IconType.Settings:
        classes = classes.concat(['fal', 'fa-cog']);
        break;
      case IconType.Logout:
        classes = classes.concat(['fal', 'fa-sign-out']);
        break;
      case IconType.Debug:
        classes = classes.concat(['fal', 'fa-bug']);
        break;
      case IconType.Building:
        classes = classes.concat(['fal', 'fa-building']);
        break;
      case IconType.Wrench:
        classes = classes.concat(['fal', 'fa-wrench']);
        break;
      case IconType.Users:
        classes = classes.concat(['fal', 'fa-users']);
        break;
      case IconType.POAExempt:
        classes = classes.concat(['fas', 'fa-parking-circle-slash']);
        break;
      case IconType.Alert:
        return Icon.layeredCounter('fal fa-bell', 'error', this.props.badgeCounter);
      case IconType.AlertNew:
        classes = classes.concat(['fal', 'fa-bell']);
        break;
      case IconType.BookReferences:
        classes = classes.concat(['fal', 'fa-book-alt']);
        break;
      case IconType.BookCodebooks:
        classes = classes.concat(['fal', 'fa-book-alt']);
        break;
      case IconType.User:
        classes = classes.concat(['fal', 'fa-user']);
        break;
      case IconType.ChevronDown:
        classes = classes.concat(['fal', 'fa-chevron-down']);
        break;
      case IconType.ChevronRight:
        classes = classes.concat(['fal', 'fa-chevron-right']);
        break;
      case IconType.UserPlus:
        classes = classes.concat(['fal', 'fa-user-plus']);
        break;
      case IconType.Bars:
        classes = classes.concat(['fas', 'fa-bars']);
        break;
      case IconType.ExternalLink:
        classes = classes.concat(['far', 'fa-external-link']);
        break;
      case IconType.History:
        return Icon.layeredCounter('fal fa-history', 'error', this.props.badgeCounter);
      case IconType.Trash:
        classes = classes.concat(['fal', 'fa-trash-alt']);
        break;
      case IconType.SolidCircle:
        classes = classes.concat(['fas', 'fa-solid', 'fa-circle']);
        break;
      case IconType.SolidSquare:
        classes = classes.concat(['fas', 'fa-solid', 'fa-square']);
        break;
      case IconType.SolidTriangle:
        classes = classes.concat(['fas', 'fa-solid', 'fa-triangle']);
        break;
      case IconType.UserSlash:
        classes = classes.concat(['fas', 'fa-user-slash']);
        break;
      case IconType.Video:
        classes = classes.concat(['fas', 'fa-video']);
        break;
      case IconType.Warning:
      default:
        classes = classes.concat(['warning-icon', 'fal', 'fa-exclamation-triangle']);
        break;
    }
    return this.props.title || this.props.forceTooltip
      ? (<i className={classes.join(' ')} title={this.props.title || "‎ "} ref={this.setRef} />)
      : (<i className={classes.join(' ')} ref={this.setRef} />);
  }

  static layeredCounter(iconClasses: string, colorClass?: BadgeProps['color'], count?: number) {
    return (
      <Badge badgeContent={count} max={9} color={colorClass || 'inherit'}>
        <i className={iconClasses} />
      </Badge>
    );
  }
}
