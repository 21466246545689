import * as moment from 'moment';
import * as actionTypes from '../../../constants';
import {
  DRGAnalyzeResultEntity,
  PDxAnalyzeResultEntity
} from '../../../models/groupingEntity';
import { EncounterEntity, EncounterState } from '../../../models';
import { pdxAPI } from '../../../services/grouping/pdx';
import { addErrors } from '../../../actions/errors';
import { encounterGroupingNeedProcess, findGroupingResult } from '../../../services/encounter/encounterMapping';
import { encounterAPI } from '../../../services/encounter';

export const pdxToggleAction = (encounter: EncounterEntity, isShowing: boolean) => (dispatch) => {
  if (isShowing && !encounter.ipGroupingType) {
    dispatch(addErrors([
      {
        description: 'Cannot start PDX analyzing without grouping',
        error: 'Cannot start PDX analyzing without grouping',
        time: moment.now(),
      }
    ]));

    return;
  }

  dispatch(pdxAnalyzeToggle(isShowing));

  if (!isShowing) {
    return;
  }

  dispatch(pdxAnalyzeBegin());

  pdxAPI
    .analyze(window.TC.Net, encounter)
    .then((res) => {
      dispatch(pdxAnalyzeCompleted(res));
    })
    .catch((reason) => {
      dispatch(pdxAnalyzeError(reason));
      dispatch(addErrors([
        {
          description: 'Cannot start PDX analyzing',
          error: 'Cannot start PDX analyzing',
          time: moment.now(),
        }
      ]));
    });
};

export const pdxAnalyzeToggle = (isShowing: boolean) => ({
  type: actionTypes.PDX_ANALYZE_TOGGLE,
  payload: isShowing
});

export const pdxAnalyzeBegin = () => ({
  type: actionTypes.PDX_ANALYZE_BEGIN,
});

export const pdxAnalyzeCompleted = (pdx: PDxAnalyzeResultEntity) => ({
  type: actionTypes.PDX_ANALYZE_COMPLETED,
  payload: pdx
});

export const pdxAnalyzeError = (error: Error) => ({
  payload: error,
  type: actionTypes.PDX_ANALYZE_ERROR,
});

export const drgAnalyzeBegin = () => ({
  type: actionTypes.DRG_ANALYZE_BEGIN,
});

export const drgAnalyzeCompleted = (drg: DRGAnalyzeResultEntity[]) => ({
  type: actionTypes.DRG_ANALYZE_COMPLETED,
  payload: drg
});

export const drgAnalyzeError = (error: Error) => ({
  payload: error,
  type: actionTypes.DRG_ANALYZE_ERROR,
});

// clear fields
export const drgAnalyzeNoDataError = () => ({
  payload: '',
  type: actionTypes.DRG_ANALYZE_ERROR,
});

// set need analyze flag
export const drgAnalyzeSetNeedAnalyzeAction = (need: boolean) => ({
  payload: need,
  type: actionTypes.DRG_NEED_ANALYZE,
});

// drg analyze action
export const drgAnalyzeAction = (encounter: EncounterState, previousResult) => (dispatch) => {
  const currentEncounter = encounter.inProgress;
  const resetDrgState = () => {
    // clear fields and update containers
    if (previousResult) {
      dispatch(drgAnalyzeBegin());
      dispatch(drgAnalyzeNoDataError());
    }

    dispatch(drgAnalyzeSetNeedAnalyzeAction(false));
  }

  // ignore drgAnalyze until we process encounter for outdated grouping
  if (encounterGroupingNeedProcess(currentEncounter, currentEncounter.ipGroupingType) !== null) {
    resetDrgState();
    return;
  }

  if (currentEncounter.ipGroupingType) {
    const groupingResult = findGroupingResult(true, currentEncounter, currentEncounter.ipGroupingType);
    // do not analyze if Drg is empty
    if (!groupingResult?.Values?.Drg) {
      resetDrgState();
      return;
    }

    dispatch(drgAnalyzeBegin());

    encounterAPI
      .analyzeEncounter(currentEncounter)
      .then((res) => {
        // console.log('result', res);
        dispatch(drgAnalyzeCompleted(res));
        return true;
      })
      .catch((reason) => {
        dispatch(drgAnalyzeError(reason));
        return false;
      });
  } else {
    resetDrgState();
  }
};
