/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/camelcase */
import forEach from 'lodash/forEach';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import { get } from '../../utils/net';

import { BaseCodeRow } from '../../models/codeGrid';
import { EncounterEntity } from '../../models/encounterEntity';
import {
  GrouperType,
  InpatientDiagnosis,
  InpatientEncounter,
  InpatientPatient,
  InpatientProcedure,
  KeyedPDxAnalysis,
  PDxAnalysis,
  PDxAnalyzeResultEntity,
  PDxAnalyzeServiceRequest,
  PDxAnalyzeServiceResult,
  PricerType,
  YesNoAuto,
  Diagnosis,
  PDxAnalyzeServiceCodeResult,
} from '../../models/groupingEntity';
import { TEENet } from '../../types/tee';
import { toServiceDate } from '../../utils/date';
import { ConcurrencyTarget } from '../../models/concurrencyTargetEnum';

const baseURL = process.env.REACT_APP_API_PATH;

const analyze = (
  Net: TEENet,
  enc: EncounterEntity): Promise<PDxAnalyzeResultEntity> => {

  const version = enc.concurrencyVersion || '';
  const url = `${baseURL}/api/CurrentEncounter/pdx/${enc.id}?groupingResultId=${enc.ipGroupingType}`;

  const mapped = mapPDxAnalyzeToServiceRequest(enc);

  const headers = {
    "If-Match": version,
  };

  const extraOptions = {
    concurrencyTarget: ConcurrencyTarget.Encounter,
    headers,
    useExactPath: true,
  };

  return get(url, extraOptions).then((result) =>
    matchAnalyzeResultsToEntity(mapped, result.body)
  );

};

const mapPDxAnalyzeToServiceRequest = (enc: EncounterEntity): PDxAnalyzeServiceRequest => {
  const patient: InpatientPatient = {
    admit_date: toServiceDate(enc.admitDate),
    admit_diagnosis: enc.admitDiagnosisCode ? enc.admitDiagnosisCode.code : '',
    age_in_years: enc.ageInYears ? enc.ageInYears.split(' ', 1)[0] : '',
    coding_system: enc.codingSystem,
    discharge_date: toServiceDate(enc.dischargeDate),
    patient_status: enc.patientStatus,
    payer_flag: '1',
    sex: enc.sex,
    total_charges: 0,
  };
  const diagnosis: InpatientDiagnosis[] = [];
  const procedure: InpatientProcedure[] = [];
  if (enc.admitDate) { patient.admit_date = toServiceDate(enc.admitDate); }
  if (enc.dischargeDate) { patient.discharge_date = toServiceDate(enc.dischargeDate); }
  const encounter: InpatientEncounter = { patient };

  forEach(enc.diagnoses.codes, (i: BaseCodeRow) => {
    if (i.code && (!i.empty && i.code)) {
      const d: InpatientDiagnosis = { code: i.code };
      if (i.presentOnAdmission) { d.present_on_admission = i.presentOnAdmission; }
      diagnosis.push(d);
    }
  });

  forEach(enc.inProcedures.codes, (i: BaseCodeRow) => {
    if (i.code && (!i.empty && i.code)) {
      const p: InpatientProcedure = { code: i.code };
      if (i.episode) { p.episode = i.episode; }
      if (i.date) { p.procedure_date = toServiceDate(i.date); }
      procedure.push(p);
    }
  });

  if (diagnosis.length) { encounter.diagnoses = { diagnosis }; }
  if (procedure.length) { encounter.procedures = { procedure }; }

  const req: PDxAnalyzeServiceRequest = {
    pdx_source: {
      encounter,
      grouping_sources: {
        grouping_source: [{
          grouper_source: {
            grouper_type: GrouperType.CMS,
          },
          medicare_provider_number: '010001',
          pricer_source: {
            pricer_type: PricerType.CmsMedicareInpatient,
          },
          primary: true,
          use_alter_grouper: false,
          use_cah_logic: YesNoAuto.No,
        }]
      },
    }
  };
  return req;
};

function matchAnalyzeResultsToEntity(request: PDxAnalyzeServiceRequest, result: PDxAnalyzeServiceResult) {
  // TODO: might want to consider factoring concurrencyid in here
  const keyedByViewId: KeyedPDxAnalysis = keyBy(result.CodesResult, (o: PDxAnalyzeServiceCodeResult) => {
    return o.ViewId;
  });
  const analyses: KeyedPDxAnalysis = mapValues(keyedByViewId, (o: PDxAnalyzeServiceCodeResult) => {
    const mapped: PDxAnalysis = {
      amlos: "", // FIXME: ? o.Values?.Amlos || "",
      viewId: o.ViewId,
      drg: o.Values?.Drg,
      drgDescription: o.Values?.DrgDescription || "",
      drgReimbursement: parseFloat(o.Values?.DrgReimbursement),
      drgWeight: parseFloat(o.Values?.DrgWeight),
      gmlos: "", // FIXME: ? o?.Values?.Gmlos || "",
      grouperType: o.GrouperDescription,
      grouperVersion: o.GrouperVersion,
      hasGrouperData: false, // FIXME: ? o.has_grouper_data === "true",
      hasPricerData: false, // FIXME: ? o.has_pricer_data === "true",
      mdc: o.Values?.Mdc,
      mdcDescription: o.Values?.MdcDescription,
    };
    return mapped;
  });
  return analyses;
}

export const pdxAPI = {
  analyze,
};
