/* eslint-disable @typescript-eslint/no-explicit-any */
import * as actionTypes from '../../constants';
import { CodeType, ModalState } from '../../models';
import { BaseCodeRow } from '../../models/codeGrid';
import { TEEModalOpts } from '../../models/teeModal';
import { FacilitySettingsState } from '../../models/facilitySettings';

export interface CodeBooksModalOptions extends ModalState {
  visible?: boolean;
  activeDataItemId?: string;    // id of edited item to find it during post / cancel
  book?: string;
  code?: string;
  crosswalkType?: string;       // view all for crosswalk
  onPosted?: (...args: any[]) => void;
  onCancelled?: () => void;
}

export interface CodeBooksModalCodePosted {
  // dataItem & field will be mixed in after TEE event trigger
  dataItem: BaseCodeRow;
  field: string;
  codes: CodeBooksModalCode[];
  facilitySettings?: FacilitySettingsState;
}

export interface CodeBooksModalCancelled {
  // dataItem & field will be mixed in after TEE event trigger
  dataItem: BaseCodeRow;
  field: string;
}

export interface CodeBooksModalCode {
  code: string;
  codeType: CodeType;
}

export const codeBooksModalAction = (opts: CodeBooksModalOptions) => (dispatch) => {
  if (opts.show) {
    dispatch(codeBooksShow(opts));
  } else {
    dispatch(codeBooksHide(opts));
  }
};

const codeBooksShow = (opts: CodeBooksModalOptions) => ({
  payload: opts,
  type: actionTypes.CODEBOOKS_MODAL_SHOW,
});

const codeBooksHide = (opts: CodeBooksModalOptions) => ({
  payload: opts,
  type: actionTypes.CODEBOOKS_MODAL_HIDE,
});

export const codeBooksModalSetRefsAction = (opts: TEEModalOpts) => ({
  payload: opts,
  type: actionTypes.CODEBOOKS_MODAL_SET_REFS,
});

export const codeBooksModalTryCodePostedAction = (c: CodeBooksModalCodePosted) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODEBOOKS_MODAL_TRY_CODE_POSTED,
  });
};

export const codeBooksModalCodePostedAction = (c: CodeBooksModalCodePosted) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODEBOOKS_MODAL_CODE_POSTED,
  });
};

export const codeBooksModalCancelledAction = (c: CodeBooksModalCancelled) => (dispatch) => {
  dispatch({
    payload: c,
    type: actionTypes.CODEBOOKS_MODAL_CANCELLED,
  });
};

export const exitAllEditingAction = () => (dispatch) => {
  dispatch({
    payload: '',
    type: actionTypes.CODE_GRID_EXIT_ALL_EDIT,
  });
};
