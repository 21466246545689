/* eslint-disable @typescript-eslint/no-explicit-any */
import { PresentOnAdmission } from './codeGrid';
import { IdDescriptionBase } from './patientEncounter';


export interface PDxAnalyzeState {
  loading: boolean;
  loadedAt?: string;
  result?: PDxAnalyzeResultEntity;
}

export interface DrgAnalyzeState {
  loading: boolean;
  loadedAt?: string;
  result?: DRGAnalyzeResultEntity[];
  needAnalyzing?: boolean;
}

interface BaseCalculateServiceRequest {
  edit_options?: EditOptions;
  general_options?: GeneralOptions;
}

export interface InpatientCalculateServiceRequest extends BaseCalculateServiceRequest {
  encounter: InpatientEncounter;
  grouping_sources?: {
    grouping_source: InpatientGroupingSource[];
  };
  resequence_options?: InpatientResequenceOptions;
}

export interface OutpatientCalculateServiceRequest extends BaseCalculateServiceRequest {
  encounter: OutpatientEncounter;
  grouping_sources?: {
    grouping_source: OutpatientGroupingSource[];
  };
  resequence_options?: ResequenceOptions;
}

// service expected version
export interface DRGCalculateServiceRequest {
  drg_source: InpatientCalculateServiceRequest;
}

export interface APCASCCalculateServiceRequest {
  outpatient_source: OutpatientCalculateServiceRequest;
}

export interface PDxAnalyzeServiceRequest {
  pdx_source: InpatientCalculateServiceRequest;
}

export interface PDxAnalyzeServiceResult {
  CodesResult: PDxAnalyzeServiceCodeResult[];
  ConcurrencyVersion: string;
}

export interface PDxAnalyzeServiceCodeResult {
  ViewId: string;
  Primary: boolean;
  GrouperDescription: string;
  GrouperVersion: string;
  UseHac: boolean;
  PricerDescription: string;
  Values: {
    Drg: string;
    Mdc: string;
    DrgDescription: string|null;
    MdcDescription: string;
    DrgWeight: string;
    DrgReimbursement: string;
  }
}

export interface MNECalculateServiceRequest {
  medical_necessity_edits_input: {
    diagnoses?: {
      diagnosis: Diagnosis[];
    };
    visit_reasons?: {
      diagnosis: Diagnosis[];
    };
    hcpcs_procedures: {
      hcpcs_procedure: HcpcsProcedure[];
    }
    age_in_years?: string;
    sex: Sex;
    state?: string;
    mac?: string;
    fi_number?: string;
    fi_state?: string;
    options: MNECalculateOptions;
    general_options?: MNECalculateGeneralOptions;
  };
}

export interface MNECalculateOptions {
  coding_system: CodingSystem;
  frequency_edits?: boolean;
  include_informational_articles?: boolean;
}

export interface MNECalculateGeneralOptions {
  strict_input_validation?: boolean;
}

export interface BaseEncounter {
  patient: BasePatient;
  diagnoses?: {
    diagnosis: Diagnosis[];
  };
  procedures?: {
    procedure: InpatientProcedure[];
  };
  condition_codes?: any;
  value_codes?: any;
}

export interface InpatientEncounter extends BaseEncounter {
  patient: InpatientPatient;
  occurrence_span_codes?: any;
  revenue_codes?: any;
}

export interface OutpatientEncounter extends BaseEncounter {
  patient: OutpatientPatient;
  procedures?: {
    procedure: OutpatientProcedure[];
  };
  visit_reasons?: {
    diagnosis: Diagnosis[];
  };
}

export interface BasePatient {
  admit_date?: string;
  discharge_date?: string;
  key_date?: string;
  admit_diagnosis?: string;
  age_in_days?: number;
  age_in_years?: string;
  date_of_birth?: number;
  patient_status?: string;
  sex?: Sex;
  source_of_admission?: string;
  total_charges?: number;
  coding_system?: CodingSystem;
}

export interface InpatientPatient extends BasePatient {
  previous_discharge_date?: string;
  newborn_weight?: number;
  override_los?: number;
  payer_flag?: string;
  non_covered_charges?: number;
  alternate_level_of_care_charges?: number;
}

export interface OutpatientPatient extends BasePatient {
  bill_type: string;
}

export enum CodingSystem {
  ICD9 = 'ICD9',
  ICD10 = 'ICD10',
}

export enum Sex {
  Zero = '0',
  One = '1',
  Two = '2',
  M = '0',
  F = '1',
  U = '2',
}

export interface Diagnosis {
  code: string;
}

export interface InpatientDiagnosis extends Diagnosis {
  present_on_admission?: PresentOnAdmission;
}

export interface InpatientProcedure {
  code: string;
  episode?: string;
  procedure_date?: string;
}

export interface OutpatientProcedure extends InpatientProcedure {
  modifier1?: string;
  modifier2?: string;
  modifier3?: string;
  modifier4?: string;
  revenue_code?: string;
  units_of_service?: number;
  charges?: number;
  non_covered_charges?: number;
}

export interface HcpcsProcedure {
  code: string;
  date: string;
}

export interface BaseGroupingSource {
  medicare_provider_number: string;
  use_national_averages_for_invalid_medicare_provider_number?: boolean;
  cost_outlier_payments?: boolean;
  grouper_source?: GrouperSource;
  pricer_source?: BasePricerSource;
}

export interface InpatientGroupingSource extends BaseGroupingSource {
  pricer_source?: InpatientPricerSource;
  primary?: boolean;
  use_alter_grouper?: boolean;
  use_cah_logic?: YesNoAuto;
}

export interface OutpatientGroupingSource extends BaseGroupingSource {
  oce_version?: OceVersion;
  pricer_source?: OutpatientPricerSource;
}

export enum OceVersion {
  OPPS = 'OPPS',
  NONOPPS = 'NONOPPS',
}

export interface GrouperSource {
  grouper_type: GrouperType;
  grouper_version?: string;
}

export enum GrouperType {
  Unknown = 'Unknown',
  CMS = 'CMS',
  AP = 'AP',
  APR = 'APR',
  Maryland = 'Maryland',
  IllinoisMedicare = 'IllinoisMedicare',
  OhioMedicaid = 'OhioMedicaid',
  CMSIPF = 'CMSIPF',
  TRICARE = 'TRICARE',
  TRICAREAPC = 'TRICAREAPC',
  NewYorkApr = 'NewYorkApr',
  OCEAPC = 'OCEAPC',
  RBRVS = 'RBRVS',
  ASC = 'ASC',
  EAPG = 'EAPG',
}

// FIXME: finish pricer source definition
export interface BasePricerSource {
  pricer_type: PricerType;
  pricer_values?: any;
  use_sequestration_reduction?: boolean;
}

export interface InpatientPricerSource extends BasePricerSource {
  rate?: number;
  simple_pricing?: boolean;
  use_cms24_reimbursement?: boolean;
  drg?: string;
}

export interface OutpatientPricerSource extends BasePricerSource {
  locality?: string;
  exclude_all_fee_schedule_pricing?: boolean;
  calculate_apc_for_all_claim_dispositions?: boolean;
}

export enum PricerType {
  CmsAsc = 'CmsAsc',
  CmsMedicareInpatient = 'CmsMedicareInpatient',
  CmsApc = 'CmsApc',
  TricareApc = 'TricareApc',
  CmsPsych = 'CMSPsychiatric',
  Hawaii = 'HawaiiMedicaidApr',
}

export enum YesNoAuto {
  Yes = 'yes',
  No = 'no',
  Auto = 'auto',
}

export interface EditOptions {
  apply_rac_edits?: boolean;
  apply_drg_edits?: boolean;
  apply_trucode_edits?: boolean;
  apply_e_code_edits?: boolean;
  apply_e_code_place_of_occurrence_edits?: boolean;
  warn_of_duplicate_procedure_codes?: boolean;
}

export interface GeneralOptions {
  return_code_descriptions?: boolean;
  strict_input_validation?: boolean;
}

export interface ResequenceOptions {
  need_procedures_resequence?: boolean;
  only_check_procedures?: boolean;
}

export interface InpatientResequenceOptions extends ResequenceOptions {
  need_diagnoses_resequence?: boolean;
  only_check_diagnoses?: boolean;
  diagnoses_resequence_count?: number;
  procedures_resequence_count?: number;
  resequence_all?: boolean;
}

export interface PDxAnalysis {
  viewId: string;
  hasGrouperData: boolean;
  drg: string;
  grouperVersion: string;
  grouperType: string;
  hasPricerData: boolean;
  drgWeight: number;
  drgReimbursement: number;
  amlos: string;
  gmlos: string;
  drgDescription: string;
  mdc: string;
  mdcDescription: string;
}

export interface KeyedPDxAnalysis {
  [index: string]: PDxAnalysis[];
}

// flat modeled version
export interface DRGCalculateRequestEntity {
  data: any;
}

export interface BaseCalculateResultEntity {
  positionEdits?: any;
  edits?: any;
  hccs?: any;
  descriptions?: any;
}

export interface DRGCalculateResultEntity extends BaseCalculateResultEntity {
  drg: string;
  description: string;
  weight: string;
  mdc: string;
  shortStay: string;
  amlos: string;
  costOutlier: string;
  reimbursement: number;
  totalOperAmount: number;
  totalCapiAmount: number;
  lowVolAmount: number;
  passThroughAmount: number;
  grouperVersion: string;
  grouperType: string;
  // FIXME: strong type these
  diagnosisFlags?: any;
  // FIXME: strong type these
  procedureFlags?: any;
  raw: any;
  sequences?: any;
}

export interface DRGAnalyzeResultEntity {
  SuggestedDRG: string;
  DRGDescription: string;
  RequirementDescription: string;
  DRGWeight: number;
  CodeLists: {
    Codes: DRGAnalyzeResultCode[];
    // DisplayName: string; // unused
  };
}

export interface DRGAnalyzeResultCode {
  Code: string;
  Desciption: string;
  CodeType: string;
}

export interface APCASCCalculateProcedureData {
  apc: string;
  apcDescription: string;
  apcWeight: string;
  statusIndicator: string;
  compAdjFlag: string;
  percent: string;
  medicareAmount: number;
  adjustedCoin: number;
  outlierAmount: number;
  payment: number;
}

export interface APCASCCalculateResultEntity extends BaseCalculateResultEntity {
  reimbursement: number;
  medicare: number;
  adjustedCoin: number;
  outlier: number;
  grouperVersion: string;
  // FIXME: strong type these
  diagnosisFlags?: any;
  // FIXME: strong type these
  procedureFlags?: any;
  raw: any;
  procedureData: any;
}

export interface MNECalculateResultEntity {
  positionEdits?: any;
  raw: any;
}

export interface PDxAnalyzeResultEntity {
  analysisByCode: KeyedPDxAnalysis;
  raw: any;
}

export interface DRGCalculateServiceResult {
  drg_calculation_results: any;
}

export interface DRGAnalyzeInput {
  drg_analysis_input: any;
}

export interface APCASCCalculateServiceResult {
  outpatient_calculation_results: any;
}

export interface MNECalculateServiceResult {
  hcpcs_procedures: any;
}

export interface GroupingInfoState {
  revenueCodes: IdDescriptionBase[];
  statusIndicators: IdDescriptionBase[];
}
