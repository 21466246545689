'use strict';

require('es5-shim');

require('json3');

require('es6-shim');

require('core-js/fn/array/includes');

if (typeof Promise === 'undefined') {
  // Rejection tracking prevents a common issue where React gets into an
  // inconsistent state due to an error, but it gets swallowed by a Promise,
  // and the user has no idea what causes React's erratic future behavior.
  require('promise/lib/rejection-tracking').enable();

  window.Promise = require('promise/lib/es6-extensions.js');
} // Object.assign() is commonly used with React.
// It will use the native implementation if it's present and isn't buggy.


Object.assign = require('object-assign'); // polyfill requestAnimationFrame since jsdom doesn't provide it yet

require('raf').polyfill(global);