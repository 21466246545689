import * as React from 'react';
import * as History from 'history';
import TruLink from '../../TruLink/TruLink';
import { IconType } from '../../Icon/Icon';
import ToolsMenu from './Menus/ToolsMenu';
import SettingsMenu from './Menus/SettingsMenu';
import UserMenu from './Menus/UserMenu';
import { EncounterState, UserRole, UserState } from '../../../models';

import './TopNavigation.less';
import { B2CState } from '../../../models/b2c';
import { AppBar, AppBarSection, AppBarSpacer, Avatar, BadgeContainer, Button, ButtonMode, Popup , Icon } from '../..';
import { getUserName } from '../../../authProvider';
import HelpMenu from './Menus/HelpMenu';
import { SystemAnnouncementsState } from '../../../models/systemAnnouncements';
import { AnnouncementList } from '../../Announcement/AnnouncementList';

enum ActiveMenuPopup {
  NONE = 'none',
  TOOLS = 'tools',
  HELP = 'help',
  RECOVERY = 'recovery',
  SETTINGS = 'settings',
  USER = 'user',
}

export interface TopNavigationProps {
  hasChangesCallback?: (to: History.History.LocationDescriptor, clickHandler?: () => void) => void;
  isModal?: boolean;
  isStandalone?: boolean;
  onCreateNewEncountersClick: () => void;
  onShowErrorsClick: () => void;
  onLogoutClick: () => void;
  onChangePasswordClick: () => void;
  errorCount?: number;
  user: UserState;
  hasActiveRequest?: boolean;
  onShowDebugClick: () => void;
  b2c: B2CState;
  isErrorMode: boolean;
  encounterOpened: boolean;
  announcements: SystemAnnouncementsState;
  onDismissAnnouncement: (id: string) => void;
  recoveredEncountersCount: number;
  refreshRecoveredEncountersCount: () => void;
  encounter: EncounterState;
}

interface TopNavigationState {
  activeMenuPopup: ActiveMenuPopup,
  showAnnouncement: boolean;
}

export default class TopNavigation extends React.PureComponent<TopNavigationProps, TopNavigationState> {
  toolsMenuAnchor: HTMLElement | null;
  settingsMenuAnchor: HTMLElement | null;
  userMenuAnchor: HTMLElement | null;
  helpMenuAnchor: HTMLElement | null;
  menuReference: HTMLUListElement | null;
  announcementTargetRef;
  recoveryPopupAnchor: HTMLElement | null;

  constructor(props: TopNavigationProps) {
    super(props);
    this.menuReference = null;
    this.onMenuOpen = this.onMenuOpen.bind(this);
    this.onPopupOpen = this.onPopupOpen.bind(this);
    this.onMenuClick = this.onMenuClick.bind(this);
    this.onMenuBlur = this.onMenuBlur.bind(this);
    this.state = {
      activeMenuPopup: ActiveMenuPopup.NONE,
      showAnnouncement: false,
    }
  }

  componentDidMount() {
    this.tryShowAnnounce();
    if(!this.props.isStandalone) {
      window.addEventListener('focus', this.pageFocus);
    }
  }

  componentWillUnmount(): void {
    if(!this.props.isStandalone) {
      window.removeEventListener('focus', this.pageFocus);
    }
  }

  componentDidUpdate() {
    this.tryShowAnnounce();
  }

  private pageFocus = () => {
    this.props.refreshRecoveredEncountersCount();
  }

  private tryShowAnnounce = () => {
    if (this.helpMenuAnchor && this.props.announcements.items.length > 0 && !this.state.showAnnouncement) {
      this.setState({ showAnnouncement: true });
    }
  }

  render() {
    // TODO: decide if we will ever bring this back
    const isDebug = false;
    const interfaceMode = false;
    const isModalMode = this.props.isModal;
    const { isStandalone, isErrorMode, recoveredEncountersCount } = this.props;
    const name = getUserName();
    const userName = name ? name.split(' ') : '';
    const avatarTextFromB2C = userName ? `${userName[0].slice(0, 1).toUpperCase()}${userName[1].slice(0, 1).toUpperCase()}` : ' ';
    const recoveredEncounterToolTip = recoveredEncountersCount > 0 ?
      `You have ${recoveredEncountersCount} recovered encounters that require action.`
      : '';

    const searchButton = this.props.user.privileges.canSearch
      ? (
        <TruLink
          to="/open"
          hasChangesCallback={this.props.hasChangesCallback}
          isErrorMode={isErrorMode}
          encounterOpened={this.props.encounterOpened}
        >
          <Button
            label="Open"
            mode={ButtonMode.Transparent} />
        </TruLink>
      ) :
      (
        <Button
          label="Open"
          disabled
          mode={ButtonMode.Transparent} />
      );

    return (
      <AppBar themeColor="inherit" positionMode="static" className="tc-appbar">
        <AppBarSection>
          {!isModalMode && !isStandalone && (
            <TruLink
              to="/"
              hasChangesCallback={this.props.hasChangesCallback}
              isErrorMode={isErrorMode}
              encounterOpened={this.props.encounter.inProgress.IsInterfaced && this.props.encounterOpened}
            >
              <h1 id="logo-title" className="title">{process.env.REACT_APP_PRODUCT_NAME} Encoder</h1>
            </TruLink>
          )}
          {(isModalMode || isStandalone) && (
            <h1 className="title">{process.env.REACT_APP_PRODUCT_NAME}</h1>
          )}
        </AppBarSection>
        <AppBarSpacer style={{ width: 32 }} />
        <AppBarSection>
          <ul>
            {!interfaceMode && !isStandalone && !isModalMode && (
              <li>{searchButton}</li>
            )}
            {!interfaceMode && !isStandalone && !isModalMode && (
              <li>
                <Button
                  label="New"
                  mode={ButtonMode.Transparent}
                  disabled={!this.props.user.privileges.canCreate}
                  onClick={this.props.onCreateNewEncountersClick} />
              </li>
            )}
            {!isModalMode && !isStandalone && (
              <li ref={e => { this.toolsMenuAnchor = e }}>
                <Button
                  label="Tools"
                  mode={ButtonMode.Transparent}
                  onClick={(e) => { this.onMenuClick(e, ActiveMenuPopup.TOOLS) }}
                  showMenu />
              </li>
            )}
          </ul>
          <Popup
            anchor={this.toolsMenuAnchor}
            show={this.state.activeMenuPopup === ActiveMenuPopup.TOOLS}
            popupClass={`popup-content popup-${ActiveMenuPopup.TOOLS}`}
            onOpen={this.onPopupOpen}
          >
            <ToolsMenu
              hasChangesCallback={this.props.hasChangesCallback}
              onBlur={this.onMenuBlur}
              onMenuOpen={this.onMenuOpen}
              isSystemAdmin={this.props.user.isSystemAdmin}
              highestRole={this.props.user.highestRole}
              isErrorMode={isErrorMode}
            />
          </Popup>
        </AppBarSection>
        <AppBarSpacer/>
        { this.props.user.organizationName && <AppBarSection className='organization-name'>
          <div>
            Org: {this.props.user.organizationName}</div>
        </AppBarSection> }
        <AppBarSpacer />
        <AppBarSection className="actions">
          <ul>
            {isDebug &&
              <li>
                <Button
                  title="Debug"
                  iconType={IconType.Debug}
                  mode={ButtonMode.Transparent}
                  onClick={this.props.onShowDebugClick}
                  className={this.props.hasActiveRequest ? 'fa-spin' : ''}
                />
              </li>
            }
            {!isModalMode && !isStandalone && (
              <li ref={e => { this.settingsMenuAnchor = e }}>
                <Button
                  title="Settings"
                  iconType={IconType.Settings}
                  mode={ButtonMode.Transparent}
                  onClick={(e) => { this.onMenuClick(e, ActiveMenuPopup.SETTINGS) }}
                  showMenu />
              </li>
            )}
            <li ref={e => {
              if (e && this.helpMenuAnchor !== e) {
                this.helpMenuAnchor = e;
              }
            }}>
              <Button
                title="Help"
                iconType={IconType.Help}
                mode={ButtonMode.Transparent}
                onClick={(e) => { this.onMenuClick(e, ActiveMenuPopup.HELP) }}
                showMenu />
            </li>
            {!isModalMode && !isStandalone && this.props.user.highestRole !== UserRole.MANAGER && (
              <li ref={e => { this.recoveryPopupAnchor = e }}>
                <BadgeContainer>
                  <TruLink
                    to="/recovery"
                    hasChangesCallback={this.props.hasChangesCallback}
                    isErrorMode={isErrorMode}
                    encounterOpened={this.props.encounterOpened}
                    className="recovery-link"
                  >
                    <Button
                      title={recoveredEncounterToolTip}
                      iconType={IconType.History}
                      mode={ButtonMode.Transparent}
                      onClick={(e) => { this.onMenuClick(e, ActiveMenuPopup.RECOVERY) }}
                      badge={recoveredEncountersCount > 0 ? recoveredEncountersCount : undefined}
                    />
                  </TruLink>
                </BadgeContainer>
              </li>
            )}
            <li>
              <BadgeContainer>
                <Button
                  title="Alerts"
                  iconType={IconType.AlertNew}
                  mode={ButtonMode.Transparent}
                  onClick={this.props.onShowErrorsClick}
                  badge={this.props.errorCount}
                />
              </BadgeContainer>
            </li>
            {window.useAzureADAuth && !isStandalone && !isModalMode && (
              <li ref={e => { this.userMenuAnchor = e }}>
                <Button
                  mode={ButtonMode.Transparent}
                  onClick={(e) => { this.onMenuClick(e, ActiveMenuPopup.USER) }}
                  showMenu
                >
                  <Avatar shape="circle" type="text">
                    {this.props.user.avatarText ? this.props.user.avatarText : avatarTextFromB2C}
                  </Avatar>
                </Button>
              </li>
            )}
          </ul>
          <Popup
            anchor={this.settingsMenuAnchor}
            popupAlign={{ horizontal: 'right', vertical: 'top' }}
            anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
            show={this.state.activeMenuPopup === ActiveMenuPopup.SETTINGS}
            popupClass={`popup-content popup-${ActiveMenuPopup.SETTINGS}`}
            onOpen={this.onPopupOpen}
          >
            <SettingsMenu
              hasChangesCallback={this.props.hasChangesCallback}
              onBlur={this.onMenuBlur}
              onMenuOpen={this.onMenuOpen}
              user={this.props.user}
              isErrorMode={isErrorMode}
              encounterOpened={this.props.encounterOpened}
            />
          </Popup>
          <Popup
            anchor={this.userMenuAnchor}
            popupAlign={{ horizontal: 'right', vertical: 'top' }}
            anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
            show={this.state.activeMenuPopup === ActiveMenuPopup.USER}
            popupClass={`popup-content popup-${ActiveMenuPopup.USER}`}
            onOpen={this.onPopupOpen}
          >
            <UserMenu
              user={this.props.user}
              logout={this.props.onLogoutClick}
              changePassword={this.props.onChangePasswordClick}
              hasChangesCallback={this.props.hasChangesCallback}
              onBlur={this.onMenuBlur}
              onMenuOpen={this.onMenuOpen}
              b2c={this.props.b2c}
              isErrorMode={isErrorMode}
              encounterOpened={this.props.encounterOpened}
            />
          </Popup>
          <Popup
            anchor={this.helpMenuAnchor}
            popupAlign={{ horizontal: 'right', vertical: 'top' }}
            anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
            show={this.state.activeMenuPopup === ActiveMenuPopup.HELP}
            popupClass={`popup-content popup-${ActiveMenuPopup.HELP}`}
            onOpen={this.onPopupOpen}
          >
            <HelpMenu
              hasChangesCallback={this.props.hasChangesCallback}
              onBlur={this.onMenuBlur}
              onMenuOpen={this.onMenuOpen}
              user={this.props.user}
              isErrorMode={isErrorMode}
              encounterOpened={this.props.encounterOpened}
            />
          </Popup>
          <Popup
            anchor={this.helpMenuAnchor}
            popupAlign={{ horizontal: 'right', vertical: 'top' }}
            anchorAlign={{ horizontal: 'right', vertical: 'bottom' }}
            show={this.state.showAnnouncement && this.props.announcements.items.length > 0}
            popupClass={`popup-content popup-${ActiveMenuPopup.HELP}`}
            contentKey={this.props.announcements.updatedTimestamp}
          >
            <AnnouncementList content={this.props.announcements.items} onDismissAnnouncement={this.props.onDismissAnnouncement} />
          </Popup>
        </AppBarSection>
      </AppBar>
    );
  }

  private onPopupOpen() {
    if (this.menuReference) {
      this.menuReference.focus();
    }
  }

  private onMenuOpen(el: HTMLUListElement | null) {
    this.menuReference = el;
  }

  private onMenuClick(e: React.MouseEvent<Element>, popupTarget: ActiveMenuPopup) {
    this.setState({ activeMenuPopup: popupTarget });
  }

  private onMenuBlur() {
    this.setState({ activeMenuPopup: ActiveMenuPopup.NONE });
  }
}
