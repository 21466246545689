import * as actionTypes from '../constants';
import { RecoveredEncountersResult, RecoveredEncountersState } from '../models';

const createEmptyEncounterRecovery = (): RecoveredEncountersState => ({
  encounters: [],
  recoveredEncountersTotal: 0,
  recoveredEncountersHash: '',
});

export const recoveredEncountersReducer = (state = createEmptyEncounterRecovery(), action) => {
  switch (action.type) {
    case actionTypes.GET_RECOVERED_ENCOUNTERS_BEGIN:
      return handleGetRecoveredEncountersBegin();
    case actionTypes.GET_RECOVERED_ENCOUNTERS_COMPLETED:
    case actionTypes.REMOVE_ENCOUNTER_FROM_RECOVERY_COMPLETED:
      return handleGetRecoveredEncountersCompleted(state, action.payload);
    case actionTypes.GET_RECOVERED_ENCOUNTERS_ERROR:
      return handleGetRecoveredEncountersError(state, action.payload);
    case actionTypes.REMOVE_ENCOUNTER_FROM_RECOVERY_ERROR:
      return handleRemoveEncounterFromRecoveryError(state, action.payload);
    case actionTypes.CLEAR_REMOVE_ENCOUNTER_FROM_RECOVERY_ERROR:
      return handleRemoveEncounterFromRecoveryError(state, undefined);
    case actionTypes.UPDATE_RECOVERED_ENCOUNTERS_TOTAL:
      return handleUpdateRecoveredEncountersTotal(state, action.payload);
    default:
      return state;
  }
};

const handleGetRecoveredEncountersBegin = () => {
  return {
    encounters: [],
  };
};

const handleGetRecoveredEncountersCompleted = (state: RecoveredEncountersState, payload: RecoveredEncountersResult) => {
  const newItems = payload?.Items || [];
  return {
    encounters: newItems,
    recoveredEncountersTotal: payload.TotalCount,
    // do not update recoveredEncountersHash to avoid cycles
  };
};

const handleGetRecoveredEncountersError = (state: RecoveredEncountersState, payload: string) => {
  return {
    encounters: [],
    errorText: payload,
  };
};

const handleRemoveEncounterFromRecoveryError = (state: RecoveredEncountersState, payload?: string) => {
  return {
    ...state,
    errorText: payload,
  };
};

const handleUpdateRecoveredEncountersTotal = (state: RecoveredEncountersState, payload: { total: number, lastId: string }) => {
  return {
    ...state,
    recoveredEncountersTotal: payload.total,
    recoveredEncountersHash: `${payload.lastId}-${payload.total}`,
  };
};
