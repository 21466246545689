import { IdDescriptionBase } from "./patientEncounter";

export enum FacilityTypes {
  ALL = '',
  PARTNER = 'partner',
  PERSONAL = 'personal',
}

export const FACILITY_PREFIX = 'FACILITY-';

export const LOAD_CUSTOM_FILTER_FACILITIES = 'loadingCustomFilterFacilities';

export const PERSONAL_FACILITY = 'PERSONAL';
export const ALL_FACILITY = 'ALL_FAC';
export const ALL = 'ALL';
export const SYSTEM = 'SYSTEM';

export const ALL_FACILITIES_ID = '*';

const All = {id: ALL, title: '- All -'};
const System = {id: SYSTEM, title: '- System -'};
const All_Fac = {id: ALL_FACILITY, title: '- All Facilities -'};
export const Personal = {id: PERSONAL_FACILITY, title: '- Personal -'};

export const CustomFacilities = [All, All_Fac, Personal, System];

export const ALL_FACILITIES_SYNONYM = '*';
