import * as moment from 'moment';
import { CodeGroup, BaseCodeRow } from "../../models/codeGrid";
import { EncounterEntity, EncounterState, GridCodeType } from "../../models";
import { ResearchPaneCodeInteraction, InteractionType } from "../../scenes/Encounter/actions/researchPane";
import { getId, updateCodes } from "./encounterCommon";
import { combineEncounterCodes, compareNoCase } from '../../utils/encounter';
import { isCriticalLevelError } from '../../scenes/Encounter/components/CodeGrid/TooltipTypes';
import {exitEditAll} from "./encounterCodeGrid";

export const handleResearchPaneCodeInteraction = (state: EncounterState, payload: ResearchPaneCodeInteraction) => {
  const inProgress = {
    ...state.inProgress,
  };

  switch (payload.type) {
    case InteractionType.Remove: {
      if (payload.gridCodeType === GridCodeType.ADMIT_DX) {
        exitEditAll(inProgress);
        inProgress.admitDiagnosisCode = {
          ...inProgress.admitDiagnosisCode,
          code: '',
          inEdit: true,
          editField:'code'
        }
      } else if (payload.gridCodeType) {
        exitEditAll(inProgress);

        // find and remove code by its referencedViewId or remove first code by value
        const collection = inProgress[payload.gridCodeType].codes;
        let found = collection.findIndex(item => item.id === payload.referencedViewId && itemHasModifier(item, payload.referencedToCodeModifier));
        if (found === -1) {
          found = collection.findIndex(item => compareNoCase(item.code, payload.code) && itemHasModifier(item, payload.referencedToCodeModifier));
        }

        if (found !== -1) {
          const newCollection: BaseCodeRow[] = [];
          for (let ind = 0, len = collection.length; ind < len; ind++) {
            const code = collection[ind];
            if (ind !== found) {
              newCollection.push(code);
            }
          }

          if (newCollection.length > 0) {
            newCollection[newCollection.length -1] = {...newCollection[newCollection.length -1], inEdit: true, editField:'code'};
          }

          inProgress[payload.gridCodeType] = {
            ...inProgress[payload.gridCodeType],
            codes: newCollection
          }
        }
      }
      break;
    }
    case InteractionType.SetAsAdmitDx: {
      // set code as current admitDX
      inProgress.admitDiagnosisCode = {
        ...inProgress.admitDiagnosisCode,
        code: payload.code
      }
      break;
    }
    case InteractionType.MoveToPDx: {
      // move code to the first position in the Diagnoses
      const newCodeGroup: CodeGroup = {
        ...inProgress.diagnoses,
        codes: []
      }

      // copy all other elements. code can be moved by reference or by value
      let found = inProgress.diagnoses.codes.findIndex(item => item.id === payload.referencedViewId);
      if (found === -1) {
        found = inProgress.diagnoses.codes.findIndex(item => compareNoCase(item.code, payload.code));
      }

      if (found !== -1) {
        for (let ind = 0, len = inProgress.diagnoses.codes.length; ind < len; ind++) {
          const code = inProgress.diagnoses.codes[ind];
          if (ind !== found) {
            newCodeGroup.codes.push(code);
          }
        }

        // insert necessary element as first
        newCodeGroup.codes.unshift(inProgress.diagnoses.codes[found]);
        inProgress.diagnoses = newCodeGroup;
      }

      break;
    }
    case InteractionType.InsertAsPDx: {
      if (payload.code) {
        // set this code to the first position in the Diagnoses
        const newCodeGroup: CodeGroup = {
          ...inProgress.diagnoses,
          codes: [{
            code: payload.code,
            empty: false,
            gridCodeType: GridCodeType.DIAGNOSES,
            id: getId(),
            // type: payload.codeType as CodeType,
            serviceCode: payload.code,

            Validations: [],
          }]
        }

        // copy all other elements
        for (let ind = 0, len = inProgress.diagnoses.codes.length; ind < len; ind++) {
          const code = inProgress.diagnoses.codes[ind];
          newCodeGroup.codes.push(code);
        }

        inProgress.diagnoses = newCodeGroup;
      }
      break;
    }
    case InteractionType.ReplacePDx: {
      if (payload.code) {
        const newId = inProgress.diagnoses.codes.length > 0 && !inProgress.diagnoses.codes[0].empty && inProgress.diagnoses.codes[0].id
          ? inProgress.diagnoses.codes[0].id : getId();

        // set this code instead of the first code in the Diagnoses
        const newCodeGroup: CodeGroup = {
          ...inProgress.diagnoses,
          codes: [{
            code: payload.code,
            empty: false,
            gridCodeType: GridCodeType.DIAGNOSES,
            id: newId,
            // type: payload.codeType as CodeType,
            serviceCode: payload.code,

            Validations: [],
          }]
        }

        // copy all other elements except first one
        for (let ind = 1, len = inProgress.diagnoses.codes.length; ind < len; ind++) {
          const code = inProgress.diagnoses.codes[ind];
          newCodeGroup.codes.push(code);
        }

        inProgress.diagnoses = newCodeGroup;
      }
      break;
    }
    case InteractionType.AddPOA: {
      exitEditAll(inProgress);
      // find corresponded code in the diagnoses and update it
      const newCodeGroup: CodeGroup = {
        ...inProgress.diagnoses,
        codes: []
      }

      for (let ind = 0, len = inProgress.diagnoses.codes.length; ind < len; ind++) {
        if (inProgress.diagnoses.codes[ind].id !== payload.referencedViewId) {
          newCodeGroup.codes.push(inProgress.diagnoses.codes[ind]);
        } else {
          newCodeGroup.codes.push({
            ...inProgress.diagnoses.codes[ind],
            inEdit: true,
            editField: "presentOnAdmission",
            presentOnAdmission: payload.poaValue,
          });
          inProgress.lastActiveCodeId = inProgress.diagnoses.codes[ind].id;
        }
      }

      inProgress.diagnoses = newCodeGroup;

      break;
    }
    case InteractionType.Add: {
      if (payload.gridCodeType && payload.code) {
        const newItem: BaseCodeRow = {
          code: payload.code,
          empty: false,
          gridCodeType: payload.gridCodeType,
          id: getId(),
          // type: payload.codeType as CodeType,
          serviceCode: payload.code,

          Validations: [],
        };

        // add code with a modifier
        if (payload.referencedToCodeModifier) {
          newItem.modifiers = [payload.referencedToCodeModifier];
        }

        const newCollection: BaseCodeRow[] = [];
        const collection = inProgress[payload.gridCodeType].codes;
        for (let ind = 0, len = collection.length; ind < len; ind++) {
          if (!collection[ind].empty) {
            newCollection.push(collection[ind]);
          }
        }

        // maximum 3 rows in Visit Reasons
        if (payload.gridCodeType !== GridCodeType.VISITREASONS || newCollection.length < 3) {
          newCollection.push(newItem);
        }

        inProgress[payload.gridCodeType] = {
          ...inProgress[payload.gridCodeType],
          codes: newCollection
        }
      }
      break;
    }
    case InteractionType.RemoveModifier: {
      const newCollection: BaseCodeRow[] = [];
      const collection = inProgress.outProcedures.codes;
      for (let ind = 0, len = collection.length; ind < len; ind++) {
        const code = { ...collection[ind] };
        if (code.id === payload.referencedViewId && code.modifiers) {
          code.modifiers = code.modifiers.filter(modifier => modifier !== payload.modifierValue);
        }

        newCollection.push(code);
      }

      inProgress.outProcedures = {
        ...inProgress.outProcedures,
        codes: newCollection
      }

      break;
    }
    case InteractionType.AddModifier: {
      const newCollection: BaseCodeRow[] = [];
      const collection = inProgress.outProcedures.codes;
      for (let ind = 0, len = collection.length; ind < len; ind++) {
        const code = { ...collection[ind] };
        if (code.id === payload.referencedViewId) {
          if (code.modifiers) {
            if (code.modifiers.length < 4 && !code.modifiers.find(modifier => modifier === payload.modifierValue)) {
              code.modifiers = [...code.modifiers, payload.modifierValue || ''];
            }
          } else {
            code.modifiers = [payload.modifierValue || ''];
          }
        }

        newCollection.push(code);
      }

      inProgress.outProcedures = {
        ...inProgress.outProcedures,
        codes: newCollection
      }

      break;
    }
    default:
      break;
  }

  // this will add empty items as last ones
  updateCodes(inProgress);

  return {
    ...state,
    dirty: true,
    inProgress,
    lastChangeTime: moment.now(),
  };
};


// return edited code if it is valid. Otherwise (no edited code or it is not valid) return null
// find inEdit code first. If not found - use lastActiveCodeId if possible
export const getEditedCode = (encounter: EncounterEntity) => {
  let code: BaseCodeRow | null = null;
  let lastActiveCode: BaseCodeRow | null = null;

  const hasAdmitDiagnosisCode = encounter.admitDiagnosisCode.code && encounter.admitDiagnosisCode.code.trim() !== '' && encounter.admitDiagnosisCode.loaded;
  if (encounter.admitDiagnosisCode.inEdit && hasAdmitDiagnosisCode) {
    code = encounter.admitDiagnosisCode;
  } else {
    if (encounter.admitDiagnosisCode.id === encounter.lastActiveCodeId && hasAdmitDiagnosisCode) {
      lastActiveCode = encounter.admitDiagnosisCode;
    }

    const allCodes = combineEncounterCodes(encounter);
    const len = allCodes.length;
    for (let ind = 0; ind < len; ind++) {
      if (!allCodes[ind].empty && allCodes[ind].serviceCode && allCodes[ind].serviceCode?.trim() !== '' && allCodes[ind].loaded) {
        if (allCodes[ind].inEdit) {
          code = allCodes[ind];
          break;
        }

        if (allCodes[ind].id === encounter.lastActiveCodeId) {
          lastActiveCode = allCodes[ind];
        }
      }
      else if (allCodes[ind].empty && allCodes[ind].inEdit) {
        code = allCodes[ind];
      }
    }
  }

  // use active code
  if (!code && lastActiveCode) {
    code = lastActiveCode;
  }

  if (!code) {
    return null;
  }

  // analyze if this code is valid
  for (let ind = 0, len = code.Validations.length; ind < len; ind++) {
    if (isCriticalLevelError(code.Validations[ind].Level) && code.Validations[ind].Field === 'Code') {
      return null;
    }
  }

  return code;
}

export const checkCanAddModifierToCode = (inProgress: EncounterEntity, ViewId: string, modifier: string) => {
  const code = inProgress.outProcedures.codes.find(item => item.id === ViewId);
  if (!code) {
    return 'Incorrect code';
  }

  const { modifiers } = code;
  if (modifiers && modifiers.length > 3 && !modifiers.find(value => value === modifier)) {
    return 'The modifier was not added. The procedure has contained 4 modifiers already.';
  }

  return '';
}

// check if modifier is in the code modifiers list
export const modifierIsInList = (list: string[] = [], modifier: string = '') => {
  return list?.find((listModifier) => listModifier?.toUpperCase() === modifier?.toUpperCase());
}

const itemHasModifier = (item: BaseCodeRow, modifier?: string) => {
  // no need to check modifier
  if (!modifier) {
    return true;
  }

  return !!modifierIsInList(item.modifiers, modifier);
}