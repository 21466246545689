import * as Promise from 'bluebird';
import * as request from 'superagent';

import { ConcurrencyTarget } from '../models/concurrencyTargetEnum';
import { ExtraOptions } from '../services/tee';
import { acquireTokenSilent, isInteractionRequiredError, pca } from '../authProvider';
import { TEEApi } from '../types/tee';
import {
  startNetActivityAction,
  stopNetActivityWithErrorAction,
  stopNetActivityAction,
  restartNetActivityAction,
  addTokenToStorageAction,
} from '../actions/netActivity';
import { store } from '../reduxStore';

const RETRY_COUNT = 3;
const singletonRequests = {};

Promise.config({
  cancellation: true,
});

export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Head = 'HEAD',
  Put = 'PUT',
  Delete = 'DELETE',
  Connect = 'CONNECT',
  Options = 'OPTIONS',
  Trace = 'TRACE',
  Patch = 'PATCH',
}

function withoutBodyMethod(url: string, extraOptions?: ExtraOptions, method = HttpMethod.Get) {
  const { dispatch } = store;
  const requestId = startNetActivityAction(dispatch, url, extraOptions?.singletonKey);

  if (window.useAzureADAuth) {
    return resolveToken(dispatch, requestId).then((token) => {
      return sendWithoutBody(url, method, extraOptions, 'Bearer', token.accessToken, dispatch, requestId);
    });
  }

  return sendWithoutBody(url, method, extraOptions, '', '', dispatch, requestId);
}

export function get(url: string, extraOptions?: ExtraOptions) {
  return withoutBodyMethod(url, extraOptions, HttpMethod.Get);
}

export function deleteEndpoint(url: string, extraOptions?: ExtraOptions) {
  return withoutBodyMethod(url, extraOptions, HttpMethod.Delete);
}

export function withBodyMethod(url: string, body: object, extraOptions?: ExtraOptions, method = HttpMethod.Post) {
  const { dispatch } = store;
  const requestId = startNetActivityAction(dispatch, url);

  if (window.useAzureADAuth) {
    return resolveToken().then((token) => {
      return sendWithBody(url, body, method, extraOptions, 'Bearer', token.accessToken, dispatch, requestId);
    });
  }

  return sendWithBody(url, body, method, extraOptions, '', '', dispatch, requestId);
}

export function post(url: string, body: any, extraOptions?: ExtraOptions) {
  return withBodyMethod(url, body, extraOptions, HttpMethod.Post);
}

export function patch(url: string, body: any, extraOptions?: ExtraOptions) {
  return withBodyMethod(url, body, extraOptions, HttpMethod.Patch);
}

export function put(url: string, body: any, extraOptions?: ExtraOptions) {
  return withBodyMethod(url, body, extraOptions, HttpMethod.Put);
}

export function sendWithoutBody(
  _url: string,
  method: HttpMethod | string,
  extraOptions?: ExtraOptions,
  accessTokenType?: string,
  accessToken?: string,
  dispatch?: Function,
  requestId?: string
) {
  const url = getUrl(_url, extraOptions);
  const headers = getHeaders(extraOptions, accessTokenType, accessToken);
  // let r: request.SuperAgentRequest;
  // Creating a promise wrapper for our superagent request allows us to avoid
  // the premature error throw behavior that is exhibited by the first failure
  // before the .retry() routine starts
  if (extraOptions?.singletonKey) {
    if (singletonRequests[extraOptions.singletonKey]) {
      restartNetActivityAction(dispatch, requestId, extraOptions.singletonKey);
      singletonRequests[extraOptions.singletonKey].cancel();
    }
    singletonRequests[extraOptions.singletonKey] = createCancellableRequest(
      url,
      headers,
      method,
      extraOptions,
      dispatch,
      requestId
    );
    return singletonRequests[extraOptions.singletonKey];
  }
  const promise = createCancellableRequest(url, headers, method, extraOptions, dispatch, requestId);
  return promise;
}

export function sendWithBody(
  _url: string,
  body: object,
  method: HttpMethod | string,
  extraOptions?: ExtraOptions,
  accessTokenType?: string,
  accessToken?: string,
  dispatch?: Function,
  requestId?: string
) {
  let count = 1;
  const url = getUrl(_url, extraOptions);
  const headers = getHeaders(extraOptions, accessTokenType, accessToken);
  const promise = new Promise<request.Response>((ok, rej) => {
    const retry = function(err, res: request.Response) {
      // GUI-2813, do not retry 5xx
      const is5xx = res && res?.status >= 500 && res?.status !== 501;
      if (is5xx || (count === RETRY_COUNT && err)) {
        stopNetActivityWithErrorAction(dispatch, requestId, is5xx ? 'Server error' : 'Too many retries', '');
        rej(err);
        return false;
      }
      count++;
      return; // returning undefined will let the logic continue with superagent
    };

    request(method, url)
      .set(headers)
      .send(body)
      .retry(RETRY_COUNT, retry)
      .timeout({
        deadline: 60000, // allow 1 minute to finish loading
        response: 60000, //  wait 60 seconds for the server to start sending
      })
      .then((res) => checkHeaders(res, extraOptions))
      .then((res: request.Response) => {
        stopNetActivityAction(dispatch, requestId, res.status);
        ok(res);
        return res;
      })
      .catch((err) => {
        stopNetActivityWithErrorAction(dispatch, requestId, err.message, err.status);

        if (err.status === 401) {
          pca.logoutRedirect();
        }

        // checking if a more detailed error message is returned from the server
        try{
          const errorDetails = JSON.parse(err.response.text);
          if(errorDetails){
            const errCopy = {...err};
            if(errorDetails.title){
              errCopy.message = errorDetails.title;
            }
            rej(errCopy);
          }
        }
        catch{
          // sallow the error
        }

        rej(err);
      });
  });
  return promise;
}

function resolveToken(dispatch?: Function, requestId?: string) {
  return acquireTokenSilent()
    .then((tokenResponse) => {
      // update token in Tee
      const TC = window.TC as TEEApi;
      if (TC) {
        const previousToken = TC.settings && TC.settings.authorizationKey;
        if (previousToken !== tokenResponse.accessToken) {
          TC.settings.authorizationKey = tokenResponse.accessToken;
        }
      }

      addTokenToStorageAction(dispatch, tokenResponse);

      return tokenResponse;
    })
    .catch((error) => {
      if (error.message === 'Unauthorized' || isInteractionRequiredError(error)) {
        console.log('Need authorization');
      }

      stopNetActivityWithErrorAction(dispatch, requestId, error.message, '');

      addTokenToStorageAction(dispatch, { error });

      throw error;
    });
}

function getUrl(_url: string, extraOptions: ExtraOptions | undefined) {
  const url = extraOptions && extraOptions.useExactPath ? _url : `${getPrefix()}/${_url}`;
  return url;
}

function checkHeaders(result: request.Response, extraOptions: ExtraOptions | undefined) {
  if (extraOptions) {
    if (extraOptions.concurrencyTarget) {
      return checkConcurrencyTarget(result, extraOptions.concurrencyTarget);
    }
  }
  return result;
}

function checkConcurrencyTarget(_result: request.Response, concurrencyTarget: ConcurrencyTarget) {
  const result = _result;
  const concurrency = result.header.etag || result.header['x-tc-concurrencyversion'];
  if (concurrency) {
    switch (concurrencyTarget) {
      case ConcurrencyTarget.Encounter:
        if (result.body) {
          result.body.ConcurrencyVersion = concurrency;
        } else {
          result.body = {
            ConcurrencyVersion: concurrency,
          };
        }
        break;
      case ConcurrencyTarget.Changeset:
        result.body = {
          changes: result.body,
          concurrencyVersion: concurrency,
        };
        break;
      default:
        console.error(`concurrency wasn't handled! ${concurrency}`);
        break;
    }
  }
  return result;
}

function getHeaders(extraOptions: ExtraOptions | undefined, accessTokenType?: string, accessToken?: string) {
  const mandatoryHeaders = {
    From: `apollo.${window.tc_git_hash}@trucode.com`,
  };

  const authorizationTokenType = accessTokenType || window.TC.settings.authorizationTokenType;
  const authorizationKey = accessToken || window.TC.settings.authorizationKey;

  const defaultHeaders = {
    Authorization: `${authorizationTokenType} ${authorizationKey}`,
    Accept: 'application/json',
  };
  if (!extraOptions?.skipContentTypeHeader) {
    defaultHeaders['Content-Type'] = 'application/json';
  }
  let headers = {};
  if (extraOptions) {
    headers =
      extraOptions.useExactHeaders && extraOptions.headers
        ? { ...extraOptions.headers, ...mandatoryHeaders }
        : { ...defaultHeaders, ...extraOptions.headers, ...mandatoryHeaders };
  }

  return headers;
}

function getPrefix() {
  const webServiceBaseUrl = {
    beta: 'https://cloud.trucode.com/',
    dev: 'https://wsdev.trucode.com/',
    // eslint-disable-next-line @typescript-eslint/camelcase
    dev_ci: 'https://wsdev.trucode.com/',
    local: 'http://wslocal.trucode.com/',
    prod: 'https://ws.trucode.com/',
    qa: '',
    stage: 'https://wsstage.trucode.com/',
  };
  const webServiceBaseApiPrefix = 'v3';
  if (window.TC.settings.useCustomEnv && window.TC.settings.customEnvUri && window.TC.settings.customEnvUri.length) {
    return window.TC.settings.customEnvUri;
  }
  return webServiceBaseUrl[window.TC.env] + webServiceBaseApiPrefix;
}

function createCancellableRequest(url, headers, method, extraOptions, dispatch?: Function, requestId?: string) {
  let count = 1;

  return new Promise<request.Response>((ok, rej) => {
    const retry = function(err, res: request.Response) {
      // GUI-2813, do not retry 5xx
      const is5xx = res && res?.status >= 500 && res?.status !== 501;
      if (is5xx || (count === RETRY_COUNT && err)) {
        stopNetActivityWithErrorAction(dispatch, requestId, is5xx ? 'Server error' : 'Too many retries', '');
        rej(err);
        return false;
      }
      count++;
      return; // returning undefined will let the logic continue with superagent
    };

    // r = request(method, url)
    // try once and then 3 retries, 4 times total
    // if response times out that's response timeout x 4 before showing error (~40sec)
    request(method, url)
      .set(headers)
      .retry(RETRY_COUNT, retry)
      .timeout({
        deadline: 60000, // allow 1 minute to finish loading
        response: 60000, //  wait 60 seconds for the server to start sending
      })
      .then((res) => {
        // console.log('status', res.status, res);
        return res;
      })
      .then((res) => checkHeaders(res, extraOptions))
      .then((res) => {
        // console.log('checked headers');
        stopNetActivityAction(dispatch, requestId, res.status);
        ok(res);
        return res;
      })
      .catch((err) => {
        stopNetActivityWithErrorAction(dispatch, requestId, err.message, err.status);

        if (err.status === 401) {
          pca.logoutRedirect();
        }

        if (err.status >= 500) {
          rej(err);
        }

        rej(err);
      });
  });
}
