/* eslint-disable @typescript-eslint/no-explicit-any */

import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import {  ALL, ALL_FACILITIES_ID, ALL_FACILITY, CurrentUserEntity, CustomFacilities, EncounterType, PERSONAL_FACILITY, PatientEncounterType, SYSTEM, UserRole, getAvailableFacilities } from "../../models";
import { SynonymItemDetails } from "./models/customization";
import { IdDescriptionBase } from "../../models/patientEncounter";

export const scrollSelectedItemIntoView = (selectedItem?) => {
  const selected = selectedItem ?? document.getElementsByClassName('k-state-selected');
  const grid = document.getElementsByClassName('k-grid-content k-virtual-content');
  if (selected && selected.length && selected[0] instanceof HTMLElement) {
    if (grid && grid.length) {
      grid[0].scrollTop = selected[0].offsetTop;
    } else {
      selected[0].scrollIntoView();
    }
  }
}

export const createCustomizeSettingFilter = (codeType?: string | null, encounterType?: ChoiceListItem, facility?: string | null) => {
  const filter: CompositeFilterDescriptor = {
    logic: 'and',
    filters: [],
  }

  if (codeType) {
    filter.filters.push({ field: 'codeType', operator: "equals", ignoreCase: true, value: codeType });
  }

  if (encounterType && !(encounterType === EncounterType.ALL)) {
    const encounterFilter: CompositeFilterDescriptor = {
      logic: 'or',
      filters: [],
    }
    encounterFilter.filters.push({ field: 'encounterType', operator: "equals", ignoreCase: true, value: encounterType });
    if (encounterType === PatientEncounterType.OP || encounterType === PatientEncounterType.IP) {
      encounterFilter.filters.push({ field: 'encounterType', operator: "equals", ignoreCase: true, value: ALL_FACILITIES_ID });
    }

    filter.filters.push(encounterFilter);
  }

  if (facility) {
    const selectFacility = facility === ALL ? ALL_FACILITIES_ID : facility;
    if (facility === PERSONAL_FACILITY) {
      filter.filters.push({ field: 'isPrivate', operator: "equals", ignoreCase: true, value: true});
    } else if (facility === ALL_FACILITY) {
      filter.filters.push({ field: 'type', operator: "equals", ignoreCase: true, value: 'Facility'});
      filter.filters.push({ field: 'facilityId', operator: "equals", ignoreCase: true, value: ALL_FACILITIES_ID});
    } else if (facility === SYSTEM) {
      filter.filters.push({ field: 'type', operator: "equals", ignoreCase: true, value: 'System'});
    } else if (selectFacility !== ALL_FACILITIES_ID) {
      filter.filters.push({ field: 'facilityId', operator: "equals", ignoreCase: true, value: selectFacility});
      filter.filters.push({ field: 'isPrivate', operator: "equals", ignoreCase: true, value: false});
    }
  }

  return filter;
}

export const mapSynonymToServiceEntity = (synonym: SynonymItemDetails, includeVersion = false) => {
  if (synonym) {
    const isPrivate = synonym.isPrivate ? ALL_FACILITIES_ID : synonym.facilityId;

    const serviceSynonym = {
      "term": synonym.term,
      "synonymText": synonym.synonymText,
      "includeSynonymInSearch": true,
      "codeType": synonym.codeType,
      "encounterType": synonym.encounterType,
      "facilityId": synonym.facilityId === ALL_FACILITY ? ALL_FACILITIES_ID : isPrivate,
      "isPrivate": synonym.isPrivate,
      "userId": synonym.userId,
      "type": synonym.type,
      ...(includeVersion && {"version": synonym.version}),
    };

    return serviceSynonym;
  }

  return {};
};

export const getFacilityShowField = (item) => {
  switch (item.id) {
    case ALL:
    case SYSTEM:
    case ALL_FACILITY:
    case PERSONAL_FACILITY:
      return item.title;
    default:
      return `${item.id} - ${item.title}`;
  }
}

export const addCustomChoices = (facilities: IdDescriptionBase[], includeAllAndSystem=true) : IdDescriptionBase[] => {
  const storedFacilities = getAvailableFacilities(facilities);
  CustomFacilities.filter((facility) => {
    if (!includeAllAndSystem) {
      return facility.id !== ALL && facility.id !== SYSTEM;
    }
    return facility;
  }).reverse().forEach(item => storedFacilities.unshift(item));

  return storedFacilities;
}

export const getUserRoleForFacility = (facilities: IdDescriptionBase[], facilityId: string, userWithPermissions: CurrentUserEntity): UserRole => {
  if (userWithPermissions.isSystemAdmin) {
    return UserRole.ADMIN;
  }

  // find viewId by id
  const facility = facilities.find((item) => item.id === facilityId);
  if (!facility) {
    return UserRole.NOROLE;
  }

  // find permission with ViewId
  const permission = (userWithPermissions.roles || []).find((role) => role.facilityId === facility.ViewId);
  if (!permission) {
    return UserRole.NOROLE;
  }

  return permission.role || UserRole.NOROLE;
}

export const userCanViewFacility = (facilities: IdDescriptionBase[], facilityId: string, userWithPermissions: CurrentUserEntity): boolean => {
  const role = getUserRoleForFacility(facilities, facilityId, userWithPermissions);
  return role > UserRole.NOROLE;
}

export const userCanEditFacility = (facilities: IdDescriptionBase[], facilityId: string, userWithPermissions: CurrentUserEntity | null): boolean => {
  if (!userWithPermissions) {
    return false;
  }

  if (facilityId === PERSONAL_FACILITY) {
    return true;
  }

  if (facilityId === ALL_FACILITIES_ID) {
    return userWithPermissions.isSystemAdmin;
  }

  const role = getUserRoleForFacility(facilities, facilityId, userWithPermissions);
  return role > UserRole.CODER;
}

export const userCanViewSynonym = (item: SynonymItemDetails, facilities: IdDescriptionBase[], user: CurrentUserEntity, userId?: string) => {
  const isAvailable = (
     // TODO: Determine if list needs to be filter based on userId (ie. can other user see synonyms created by other users)
    // I replaced !item.userId with item.isSystem, is it correct?
    // the user can view system synonyms
    item.isSystem
    // the user can view personal synonyms
    || (item.isPrivate && item.userId === userId)
    // facility synonym and the user has rights in this facility
    || (item.type === 'Facility' && userCanViewFacility(facilities, item.facilityId || '', user))
    // all facilities synonym and the user has any facility
    || (item.type === 'Facility' && item.facilityId === ALL_FACILITIES_ID && facilities.length > 0)
  );

  // console.log(item, isAvailable);
  return isAvailable;
}

export const userCanEditOrDeleteSynonym = (item: SynonymItemDetails, facilities: IdDescriptionBase[], user: CurrentUserEntity | null, userId?: string) => {
  if (!user) {
    return false;
  }

  if (item.isSystem || item.type === 'System') {
    return false;
  }

  if (user.isSystemAdmin) {
    return true;
  }

  // only system admin can update * synonyms
  if (item.type === 'Facility' && item.facilityId === ALL_FACILITIES_ID) {
    return false;
  }

  // can rights in the facility
  if (item.type === 'Facility' && userCanEditFacility(facilities, item.facilityId || '', user)) {
    return true;
  }

  if (item.isPrivate && item.userId === userId) {
    return true;
  }

  // other situations
  return false;
}

export const filterAvailableFacilities = (facilities: IdDescriptionBase[], user: CurrentUserEntity | null, canAdd: boolean) => {
  if (!user) {
    return [];
  }

  if (user.isSystemAdmin) {
    return facilities;
  }

  return facilities.filter((facility) => {
    if (facility.id === PERSONAL_FACILITY) {
      return true;
    }

    if (facility.id === ALL_FACILITIES_ID) {
      return false;
    }

    return canAdd ? userCanEditFacility([facility], facility.id, user)
      : userCanViewFacility([facility], facility.id, user);
  });
}

export const isAdminInAnyFacility = (facilities: IdDescriptionBase[], user: CurrentUserEntity | null) => {
  const availableFacilities = filterAvailableFacilities(facilities, user, true);
  return availableFacilities.length > 0;
}
