import * as actionTypes from '../constants';
import { EncoderUserListResultEntity } from '../models';
import { CustomizationState } from '../models/customization';
import { AuditExportLogStatus } from '../scenes/SystemPreferences/models/systemPreferences';

const createEmptyCustomization = (): CustomizationState => {
  return {
    loading: false,
    updating: false,
    newSynonymId: undefined,
    synonymsList: { totalCount: 0, items: [] },
    codingAdviceList: [],
    codeSetsList: [],
    importStatus: { importing: false, importedCount: 0, ignoredCount: 0, errorsCount: 0 },
    userWithRoles: null,
  };
};

export const customizationReducer = (state = createEmptyCustomization(), action) => {
  switch (action.type) {
    case actionTypes.GET_SYNONYMS_LIST_START:
      return handleGetSynonymsListStart(state);
    case actionTypes.IMPORT_SYNONYMS_LIST_START:
      return handleImportSynonymsListStart(state);
    case actionTypes.EXPORT_SYNONYMS_LIST_START:
      return handleExportSynonymsListStart(state);
    case actionTypes.EXPORT_SYNONYMS_LIST_COMPLETED:
      return handleExportSynonymsListCompleted(state, action.payload);
    case actionTypes.EXPORT_SYNONYMS_LIST_ERROR:
      return handleExportSynonymsListError(state, action.payload);
    case actionTypes.EXPORT_SYNONYMS_LIST_SET_FIELDS:
      return handleExportSynonymsListSetFields(state, action.payload);
    case actionTypes.GET_SYNONYMS_LIST_END:
      return handleGetSynonymsListEnd(state);
    case actionTypes.EXPORT_SYNONYMS_LIST_END:
    case actionTypes.IMPORT_SYNONYMS_LIST_END:
      return handleImportExportSynonymsListEnd(state);
    case actionTypes.IMPORT_SYNONYMS_LIST_COMPLETED:
      return handleImportSynonymsListCompleted(state, action.payload);
    case actionTypes.IMPORT_SYNONYMS_LIST_ERROR:
      return handleImportSynonymError(state, action.error);
    case actionTypes.GET_SYNONYMS_LIST_COMPLETED:
      return handleGetSynonymsListComplete(state, action.payload);
    case actionTypes.CREATE_SYNONYM_COMPELETED:
      return handleCreateSynonymComplete(state, action.payload);
    case actionTypes.CREATE_SYNONYM_START:
      return handleCreateSynonymStart(state);
    case actionTypes.UPDATE_SYNONYM_START:
      return handleUpdateSynonymStart(state);
    case actionTypes.CREATE_SYNONYM_END:
      return handleCreateSynonymEnd(state);
    case actionTypes.UPDATE_SYNONYM_COMPELETED:
      return handleUpdateSynonymComplete(state, action.payload);
    case actionTypes.UPDATE_SYNONYM_ERROR:
      return handleUpdateSynonymError(state, action.error);
    case actionTypes.CREATE_SYNONYM_ERROR:
      return handleCreateSynonymError(state, action.error);
    case actionTypes.SET_SYNONYMNS_USER_WITH_ROLES:
      return handleSetSynonymnsUserWithRoles(state, action.payload);
    default:
      return state;
  }
};
const handleGetSynonymsListStart = (state: CustomizationState): CustomizationState => {
  return {
    ...state,
    synonymsList: { items: [], totalCount: 0 },
    loading: true,
  };
};

const handleGetSynonymsListEnd = (state: CustomizationState): CustomizationState => {
  return {
    ...state,
    loading: false,
  };
};

const handleGetSynonymsListComplete = (state: CustomizationState, data): CustomizationState => {
  return {
    ...state,
    synonymsList: data,
    loading: false,
  };
};

const handleImportSynonymsListStart = (state: CustomizationState): CustomizationState => {
  return {
    ...state,
    loading: true,
    importStatus: {
      ...state.importStatus,
      importing: true,
    },
    exportStatus: undefined,
    error: undefined,
  };
};

const handleExportSynonymsListStart = (state: CustomizationState): CustomizationState => {
  return {
    ...state,
    loading: true,
    exportStatus: AuditExportLogStatus.Exporting,
    exportData: undefined,
    exportError: '',
    error: undefined,
  };
};

const handleExportSynonymsListError = (state: CustomizationState, error): CustomizationState => {
  return {
    ...state,
    loading: false,
    exportStatus: AuditExportLogStatus.Failed,
    exportData: undefined,
    exportError: error,
  };
};

const handleExportSynonymsListCompleted = (state: CustomizationState, data): CustomizationState => {  
  return {
    ...state,
    loading: false,
    exportStatus: AuditExportLogStatus.Done,
    exportData: data,
    exportError: '',
  };
};

const handleExportSynonymsListSetFields = (state: CustomizationState, payload): CustomizationState => {
  return {
    ...state,
    exportStatus: payload.exportStatus,
    exportData: payload.exportData,
    exportError: payload.exportError,
  };
};

const handleImportExportSynonymsListEnd = (state: CustomizationState): CustomizationState => {
  return {
    ...state,
    loading: false,
    importStatus: {
      ...state.importStatus,
      importing: false,
    },
  };
};

const handleSetSynonymnsUserWithRoles = (state: CustomizationState, userWithRoles): CustomizationState => {
  return {
    ...state,
    userWithRoles,
  };
};

const handleImportSynonymsListCompleted = (state: CustomizationState, data): CustomizationState => {
  return {
    ...state,
    loading: false,
    importStatus: {
      ...state.importStatus,
      importing: false,
      importedCount: data.importedCount,
      ignoredCount: data.ignoredCount,
      errorsCount: data.errorsCount,
    },
  };
};

const handleImportSynonymError = (state: CustomizationState, data): CustomizationState => {
  return {
    ...state,
    error: { action: 'Import', errorMsg: 'Import failed. The import file does not appear to be in correct format' },
    importStatus: {
      importedCount: 0,
      ignoredCount: 0,
      errorsCount: 0,
      importing: false,
    },
  };
};

const handleCreateSynonymStart = (state: CustomizationState): CustomizationState => {
  return {
    ...state,
    loading: true,
  };
};

const handleCreateSynonymEnd = (state: CustomizationState): CustomizationState => {
  return {
    ...state,
    loading: false,
  };
};

const handleCreateSynonymComplete = (state: CustomizationState, data): CustomizationState => {
  return {
    ...state,
    newSynonymId: data.id,
    error: undefined,
  };
};

const handleUpdateSynonymStart = (state: CustomizationState): CustomizationState => {
  return {
    ...state,
    updating: true,
    error: undefined,
  };
};

const handleUpdateSynonymComplete = (state: CustomizationState, synonym): CustomizationState => {
  return {
    ...state,
    updating: false,
    error: undefined,
  };
};

const handleUpdateSynonymError = (state: CustomizationState, data): CustomizationState => {
  return {
    ...state,
    updating: false,
    error: { action: 'Update', errorMsg: 'Failed to update synonym. Please try again.' },
  };
};

const handleCreateSynonymError = (state: CustomizationState, data): CustomizationState => {
  return {
    ...state,
    loading: false,
    error: { action: 'Create', errorMsg: 'Failed to create synonym. Please try again.' },
  };
};
