import * as actionTypes from '../../../constants';
import { CodeType, EncounterEntity, GridCodeType } from '../../../models';
import { PresentOnAdmission } from '../../../models/codeGrid';

export enum InteractionType {
  Remove,
  MoveToPDx,
  SetAsAdmitDx,
  Add,
  InsertAsPDx,
  ReplacePDx,
  ViewInTabular,
  AddModifier,
  RemoveModifier,
  AddPOA,
}

export enum EditType {
  Article,
  Code,
  Unknown,
}

export enum PopupCaller {
  ResearchPane,
  PreventSaveDialog,
  Unknown,
}

export interface ResearchPaneCodeInteraction {
  code?: string;
  codeType?: CodeType|EditType;
  type: InteractionType;
  gridCodeType?: GridCodeType;
  referencedViewId?: string | null;
  poaValue?: PresentOnAdmission;
  modifierValue?: string;
  referencedToCodeModifier?: string;
}

export interface EditLocateEvent {
  viewId: string;
  gridCodeType: GridCodeType;
  field?: string;
}

export const editLocateEventAction = (e: EditLocateEvent) => (dispatch) => {
  dispatch({
    payload: e,
    type: actionTypes.RESEARCH_EDIT_LOCATE_EVENT,
  });
}

export const researchPaneCodeInteractionAction = (e: ResearchPaneCodeInteraction) => (dispatch) => {
  dispatch({
    payload: e,
    type: actionTypes.RESEARCH_PANE_INTERACTION,
  });
  switch (e.type) {
    case InteractionType.Add:
    case InteractionType.AddPOA:
    case InteractionType.InsertAsPDx:
    case InteractionType.MoveToPDx:
    case InteractionType.Remove:
    case InteractionType.RemoveModifier:
    case InteractionType.ReplacePDx:
      break;
    default:
      break;
  }
};
