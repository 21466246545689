/* eslint-disable @typescript-eslint/no-explicit-any */
import { createLogic } from 'redux-logic';
import * as actionTypes from '../constants';
import { LogicLifecycleParams } from '../reduxStore';
import { drgAnalyzeSetNeedAnalyzeAction } from '../scenes/Encounter/actions/grouping';

export const drgAnalyzeLogic = createLogic({
  type: [
    actionTypes.OPEN_ENCOUNTER_BY_ID_COMPLETED,
    actionTypes.PROCESS_ENCOUNTER_COMPLETED,
    actionTypes.ENCOUNTER_CHANGE_GROUPING,
  ],
  latest: true, // take latest only

  process({ getState }: LogicLifecycleParams, dispatch: any, done: any) {
    // mark that we need in drg analyze
    dispatch(drgAnalyzeSetNeedAnalyzeAction(true));
    done();
  },
});

// multiple logic groups can be exported here
export default [drgAnalyzeLogic];
