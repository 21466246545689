import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { BaseCodeRow, CodeGroup } from './codeGrid';
import { ServiceEncounterProcessMNEResult, ServiceEncounterValidationResult, ServiceEncounterView, ServiceResultGroupingResultResult } from './encounterServiceEntity';
import { CodingSystem, Sex } from './groupingEntity';
import { ValuesRow } from './valuesGrid';

export interface BaseEncounterEntity {
  id: string;
  viewId?: string | null;
  type: string;
  ipAdmitdx?: string;

  concurrencyVersion?: string;
  lockedBy?: string | null;
  readOnly?: boolean | null;
  ipGroupingType?: string;    // current grouping ViewId in IP
  opGroupingType?: string;    // current grouping ViewId in not IP

  admitDiagnosisCode: BaseCodeRow;
  diagnoses: CodeGroup;
  visitReasons: CodeGroup;
  inProcedures: CodeGroup;
  outProcedures: CodeGroup;
  lastActiveCodeId?: string;   // last active code for ResearchPane

  admitDate?: string;
  dischargeDate?: string;
  admitDiagnosis?: string;
  admitDiagnosisDescription?: string;
  ageInYears?: string;
  patientStatus?: string;
  sex?: Sex;
  codingSystem?: CodingSystem;
  billType?: string;

  // Patient properties
  medicalRecordNumber?: string;
  firstName?: string;
  lastName?: string;
  middleInitial?: string;
  dateOfBirth?: string;
  // Encounter properties
  accountNumber?: string;
  financialClass?: string;
  totalCharges?: number;
  thirdPartyLiability?: number;
  overrideLOS?: number;

  birthWeight?: string;
  los?: number;
  attendingMd?: string;
  managedCareFlag?: string;

  facility?: string;
  recordStatus?: string;
  service?: string;
  ofa?: string;
  payerFlag?: string;

  // Supplementary fields
  primaryCoder?: string;
  secondaryCoder?: string;
  reviewer?: string;
  reviewDate?: string;
  memo?: string;
  createdOn?: string;
  finalizedOn?: string;
  modifiedOn?: string;
  totalCodingTime?: number;
  customField1?: string;
  customField2?: string;
  customField3?: string;
  customField4?: string;

  conditionCodes: ValuesRow[];
  valueCodes: ValuesRow[];

  IsInterfaced?: boolean;
  InterfaceCallbackUrl?: string;
  InterfaceMode?: string;

  IsDirty?: boolean | null;         // encounter is dirty after opening

  billingNotes: string;
}

// FIXME: decide whether to make the encounter generic or use optional properties
// for those that aren't shared between IP and OP
// FIXME: type these to enums where possible
export interface EncounterEntity extends BaseEncounterEntity {
  // raw information from the services
  ValidationResult: ServiceEncounterValidationResult | null;
  GroupingResults: ServiceResultGroupingResultResult[];
  ProcessMNEResult: ServiceEncounterProcessMNEResult | null;
}

export interface EncounterSnapshotEntity {
  Encounter: BaseEncounterEntity;
  FacilitySnapshot: FacilitySnapshot;
}

export interface FacilitySnapshot {
  SnapshotId: string;
  RecordStatus: string;
  EncounterType: string;
  DocumentId: string;
  Sex: string;
  PatientStatus: string;
  SourceOfAdmission: string;
  PayerFlag: string;
  IsInpatient: boolean;
  AllowInvalidModifiers: boolean;
  EnableECodeEdits: boolean;
  EnableECodePlaceOfOccurrenceEdits: boolean;
  EnableECauseMorbidityEdits: boolean;
  EnableECauseActivityEdits: boolean;
  EnableECauseStatusEdits: boolean;
  EnableECausePlaceOfOccurrenceEdits: boolean;
  WarnOfDuplicateProcedureCodes: boolean;
  PromptForNewbornWeight: boolean;
  ExemptFromHospitalAcquiredConditionLogic: boolean;
  CcnType: string;
  DxCodesToResequence: number;
  PrCodesToResequence: number;
  ResequenceAll: boolean;
  CMSCertificationNumber: string;
  ExcludeAllFeeSchedulePricing: boolean;
  CalculateApcForAllClaimDispositions: boolean;
  CalculateDrgs: boolean;
  DaysAProcedureDateMayPrecedeAdmitDate: number;
  VerifyProviderInChoiceList: boolean;
  MissingPOAErrorLevel: string;
  Mac: string;
  EnableMedicalNecessityFrequencyEdits: boolean;
  EnableMedicalNecessityArticlePolicies: boolean;
  UseSequestrationReduction: boolean;
  DefaultBillType: string;
  PricerInstance: string;
  BaseDate: string;
}
export interface EncounterState {
  saved: EncounterEntity;                   // last saved content
  savedServiceEntity: ServiceEncounterView; // last saved content in services format
  inProgress: EncounterEntity;              // current content

  dirty: boolean;           // was changed after last opening / saving

  lastUpdateTime?: number;  // the moment of the last syncronization with services
  lastChangeTime?: number;  // the moment of the last user change
  lastErrorTime?: number;   // the moment of the last error
  errorAttempt?: number;    // errors attempts

  needInitialProcess?: boolean; // we need to perform process with encounter after opening
  needHandling?: boolean;       // we must perform some special handling for encounter after processing (like autoresequence)
  hangingProcessings?: string[];  // outdated groupings that cannot be processed to skip them next time and avoid infinite processing requests
  closedByAnotherSession?: boolean;
  checkIfCodesNeedResequencing?: boolean;  // codes are "dirty" - check if the codes need to be resquenced
  codesAutoResequenced?: boolean;         // codes were auto resequenced
}

export interface EncounterSnapshotState {
  snapshot: EncounterSnapshotEntity | null;
  fetching: boolean;
  fetched: boolean;
}

export interface InterfacePushOptions {
  debug?: boolean;
}

export interface RecoveredEncounterResultEntity {
  AccountNumber: string,
  EncounterId: string,
  MedicalRecordNumber: string,
  LastUpdated: string;
  Interfaced: boolean,
  IsActive: boolean,
  IsDirty: boolean,
  LastName: string,
  MiddleInitial: string,
  FirstName: string,
  DateOfBirth: string,
  FacilityId: string,
  PatientAge?: string,
  ExpiringIn?: number
}

export interface RecoveredEncounterEntity {
  accountNumber: string,
  id: string,
  mrn: string,
  lastUpdated: string;
  interfaced: boolean,
  isActive: boolean,
  isDirty: boolean,
  dateOfBirth: string,
  patientAge?: string,
  facilityId: string,
  fullName: string,
  lastUpdatedDate?: Date,
  expiringIn?: number,
  dateOfBirthAgeData?: Date
}

export interface RecoveredEncountersResult {
  Items: RecoveredEncounterResultEntity[];
  TotalCount: number;
}

export interface RecoveredEncountersState {
  encounters: RecoveredEncounterResultEntity[];
  errorText?: string;
  recoveredEncountersTotal: number;
  // used for quick comparison: total + last encounter id, not ideal but fast
  recoveredEncountersHash: string;
}

export enum InterfaceModes {
  Legacy = "Legacy",
  Epic = "Epic30",
}
