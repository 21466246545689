import * as moment from 'moment';
import * as actionTypes from '../../../constants';
import { ErrorDetails, EncounterEntity, UserRole, UserRoleMap } from '../../../models';
import { choiceListsAPI } from '../../../services/choiceLists';
import { PartialChoiceLists, ChoiceListData, IdDescriptionBase } from '../../../models/patientEncounter';
import { getSingleChoiceListCompleted } from '../../../actions/choiceLists';
import { facilitySettingsAPI } from '../../../services/facility/settings';
import { parseServiceErrors } from '../../../services/encounter/serviceErrors';
import { FacilityServiceFieldSetting, FacilityPreferences, FacilityData } from '../../../models/facilitySettings';
import { addErrors } from '../../../actions/errors';
import { checkFieldForCollection } from '../../../utils/ddl';
import { parseSettings } from '../../../utils/fieldSettings';
import { ServiceEncounterEntity } from '../../../models/encounterServiceEntity';

/*
  Load all necessary data for the current facility:
    - reload facilities list with current encounter facility if it is inactive
    - load encounter types and select first one if we have not encounter type
    - load all choice lists
    - load facility preferences
    - load facility settings
*/
export const getFacilityData = (dispatch, facility: string, encounterType: string, interfaceMode: boolean, facilities: IdDescriptionBase[], encounter?: EncounterEntity) => {
  const facilityData: FacilityData = {};

  // use loaded facilitites
  let currentEncounterFacilitiesPromise =
    Promise.resolve()
      .then(() => { return facilities; });

  // if we have not current facility in the already loaded list - reload facilities with it
  if (!facilities.find(item => item.ViewId === facility)) {
    currentEncounterFacilitiesPromise =
      choiceListsAPI.getFacilities(facility)
        .then((currentEncounterFacilities) => {
          return currentEncounterFacilities;
        });
  }

  // get necessary facilities
  return currentEncounterFacilitiesPromise
    .then((currentEncounterFacilities) => {
      facilityData.currentEncounterFacilities = currentEncounterFacilities;

      // load choice lists (at least mandatory choice lists must be loaded (EncounterType)). Use encounter object to define selected and non-active values.
      return choiceListsAPI.getInitialChoiceListDataByFacility(facility, encounterType, encounter)
        .then((choiceListsData: ChoiceListData[]) => {
          // use first available encounter type if type is not selected or is not existing in the new facility
          const encounterTypes = choiceListsData.find((item) => item.name === 'encounterTypes');
          if (!encounterTypes || !encounterTypes.values || !encounterTypes.values.length) {
            facilityData.errors =  [{ error: "No Encounter Types", description: "No Encounter Types", time: moment.now() }];

            return facilityData;
          }

          const existingEncounterType = checkFieldForCollection(encounterType, encounterTypes.values);
          const type = existingEncounterType || encounterTypes.values[0].ViewId;

          if (!type) {
            facilityData.errors =  [{ error: "Incorrect Encounter Type", description: "Incorrect Encounter Type", time: moment.now() }];

            return facilityData;
          }

          /* TODO: Keep only encounter types in the final code here
          dispatch(getSingleChoiceListCompleted([{
            name: 'encounterTypes',
            values: encounterTypes.values
          }]));
          */

          facilityData.choiceListsData = choiceListsData;

          return facilitySettingsAPI.getFacilityPreferences(facility)
            .then((preferences: FacilityPreferences) => {
              facilityData.preferences = preferences;

              return facilitySettingsAPI.getFacilityFieldSettings(facility, type, interfaceMode);
            })
            .then((settings) => {
              facilityData.fieldSettings = settings;

              const partialChoiceLists: PartialChoiceLists = {
                // it is enough to have only current facility id
                facilities: [{ id: facility, title: facility, ViewId: facility }],
                encounterTypes: encounterTypes.values,
              }

              facilityData.partialChoiceLists = partialChoiceLists;

              return facilityData;
            })
            .catch((reason: Error) => {
              facilityData.errors = parseServiceErrors(reason, 'Cannot Load Facility Settings');

              return facilityData;
            });
        })
        .catch((reason: Error) => {
          facilityData.errors = parseServiceErrors(reason, 'Cannot Load Choice Lists');

          return facilityData;
        });
    })
    .catch((reason: Error) => {
      facilityData.errors = parseServiceErrors(reason, 'Cannot Load Facilities List');

      return facilityData;
    });
};

// reload only field settings for the necessary encounterType
export const getFacilityFieldSettings = (dispatch, facility: string, encounterType: string, interfaceMode: boolean) => {
  dispatch(setFacilitySettingsBegin());

  return facilitySettingsAPI.getFacilityFieldSettings(facility, encounterType, interfaceMode)
    .then((settings) => {
      dispatch(setFacilitySettings(settings));

      return true;
    })
    .catch((reason: Error) => {
      const errors = parseServiceErrors(reason, 'Cannot Load Facility Settings');
      dispatch(getFacilitySettingsError(errors));

      return false;
    });
};

const setFacilitySettingsBegin = () => ({
  type: actionTypes.SET_FACILITY_SETTINGS_BEGIN,
});

const setFacilitySettings = (settings: FacilityServiceFieldSetting[]) => ({
  payload: {
    serviceSettings: settings,
    clientSettings: parseSettings(settings)
  },
  type: actionTypes.SET_FACILITY_SETTINGS,
});

const getFacilitySettingsError = (errors: ErrorDetails[]) => ({
  payload: errors,
  type: actionTypes.GET_FACILITY_SETTINGS_FAIL,
});

const setFacilityPreferences = (preferences: FacilityPreferences) => ({
  payload: {
    servicePreferences: preferences,
  },
  type: actionTypes.SET_FACILITY_PREFERENCES,
});

export const setCurrentEncounterFacilities = (currentEncounterFacility: string, currentEncounterFacilities: IdDescriptionBase[]) => ({
  payload: {
    currentEncounterFacility,
    currentEncounterFacilities,
  },
  type: actionTypes.SET_CURRENT_ENCOUNTER_FACILITIES,
});

// apply loaded facility data to storages
export const applyFacilityData = (dispatch, facility: string, facilityData?: FacilityData | null, showErrors = true) => {
  if (!facilityData) {
    return false;
  }

  if (facilityData.errors && facilityData.errors.length) {
    if (showErrors) {
      dispatch(addErrors(facilityData.errors)); 
    }

    return false;
  }

  if (!facilityData.currentEncounterFacilities || !facilityData.choiceListsData || !facilityData.preferences || !facilityData.fieldSettings) {
    return false;
  }

  dispatch(setCurrentEncounterFacilities(facility, facilityData.currentEncounterFacilities));
  dispatch(getSingleChoiceListCompleted(facilityData.choiceListsData));
  dispatch(setFacilityPreferences(facilityData.preferences));
  dispatch(setFacilitySettings(facilityData.fieldSettings));

  return true;
};

interface ChoiceListData {
  name: string;
  values: IdDescriptionBase[];
};

/*
  Load all necessary data for the current facility from the preloaded fields:
*/
export const getFacilityDataFromPreloadedObject = (dispatch, facility: string, encounterType: string, interfaceMode: boolean, facilities: IdDescriptionBase[], serviceEncounter: ServiceEncounterEntity) => {
  const facilityData: FacilityData = {};

  return Promise.resolve()
  .then(() => {
    if (!serviceEncounter.Facility?.ViewId || !serviceEncounter.Facility?.Id || !serviceEncounter.ChoiceListItems) {
      facilityData.errors =  [{ error: "No Facility Data", description: "No Facility Data", time: moment.now() }];
      return facilityData;
    }

    // prepare facility choicelist
    const facilityRecord = {
      id: serviceEncounter.Facility.Id,
      title: serviceEncounter.Facility.Description || "",
      CurrentUserRole: serviceEncounter.Facility.CurrentUserRole,
      ViewId: serviceEncounter.Facility.ViewId,
    }

    const previousFacilities = [...facilities];
    const oldIndex = previousFacilities.findIndex(item => item.ViewId === facility);

    if (oldIndex === -1) {
      // reload all facilities
      facilityData.reloadFacilities = true;
    }

    facilityData.currentEncounterFacilities = oldIndex === -1
      // use returned record and reload facilities
      ? [facilityRecord]
      // use updated record
      : previousFacilities.map((item) => item.ViewId === facility ? facilityRecord : item);
 
    // prepare choicelists
    const choiceListsData: ChoiceListData[] = [];
    [
      { name: "encounterTypes", serviceName: "EncounterType"},
      { name: "patientStatuses", serviceName: "PatientStatus"},
      { name: "services", serviceName: "Service"},
      { name: "financialClasses", serviceName: "FinancialClass"},
      { name: "recordStatuses", serviceName: "RecordStatus"},
      { name: "sexes", serviceName: "Sex"},
      { name: "ofa", serviceName: "SourceOfAdmission"},
      { name: "providers", serviceName: "Providers"},
    ].forEach((item) => {
      const list: IdDescriptionBase[] = [];
      const serviceItem = serviceEncounter.ChoiceListItems && serviceEncounter.ChoiceListItems[item.serviceName];
      if (serviceItem) {    
        const listItem = {
          id: serviceItem.Id,
          title: serviceItem.Description,
          ViewId: serviceItem.ViewId,
        }

        list.push(listItem);
      }

      choiceListsData.push({
        name: item.name,
        values: list,
      })
    })

    // providers array
    const providers: IdDescriptionBase[] = [];
    if (serviceEncounter.ChoiceListItems.EncounterProvider) {
      const serviceItem = serviceEncounter.ChoiceListItems.EncounterProvider;
      providers.push({
        id: serviceItem.Id || '',
        title: serviceItem.Description || '',
        name: serviceItem.Description || '',
        ViewId: serviceItem.ViewId,
        service: serviceItem.Service || '',
      })
    }

    const serviceProviders = serviceEncounter.ChoiceListItems.Providers;
    if (serviceProviders && serviceProviders.length > 0) {
      for (let pind = 0, plen = serviceProviders.length; pind < plen; pind += 1) {
        const serviceItem = serviceProviders[pind];
        providers.push({
          id: serviceItem.Id || '',
          title: serviceItem.Description || '',
          name: serviceItem.Description || '',
          ViewId: serviceItem.ViewId,
          service: serviceItem.Service || '',
        })
      }
    }

    choiceListsData.push({
      name: 'providers',
      values: providers,
    });

    // check encounter type
    // use first available encounter type if type is not selected or is not existing in the new facility
    const encounterTypes = choiceListsData.find((item) => item.name === 'encounterTypes');
    if (!encounterTypes || !encounterTypes.values || !encounterTypes.values.length) {
      facilityData.errors =  [{ error: "No Encounter Types", description: "No Encounter Types", time: moment.now() }];

      return facilityData;
    }

    const existingEncounterType = checkFieldForCollection(encounterType, encounterTypes.values);
    const type = existingEncounterType || encounterTypes.values[0].ViewId;

    if (!type) {
      facilityData.errors =  [{ error: "Incorrect Encounter Type", description: "Incorrect Encounter Type", time: moment.now() }];

      return facilityData;
    }

    // payerFlags billingNotes are not provided from the start
    choiceListsData.push({ name: 'payerFlags', values: [] });
    choiceListsData.push({ name: 'billingNotes', values: [] });
    
    facilityData.choiceListsData = choiceListsData;

    // facility preferences
    facilityData.preferences = {
      ...serviceEncounter.Facility,
      CurrentUserRole: UserRoleMap[serviceEncounter.Facility.CurrentUserRole || 'NoRole'] || UserRole.NOROLE,
    };

    // field settings
    facilityData.fieldSettings = serviceEncounter.ChoiceListItems.FieldSettings;

    // partial choice lists
    const partialChoiceLists: PartialChoiceLists = {
      // it is enough to have only current facility id
      facilities: [{ id: facility, title: facility, ViewId: facility }],
      encounterTypes: encounterTypes.values,
    }

    facilityData.partialChoiceLists = partialChoiceLists;

    return facilityData;
  })
  .catch((reason: Error) => {
    facilityData.errors = [{ error: "Cannot Prepare Encounter Data", description: "Cannot Prepare Encounter Data", time: moment.now() }];

    return facilityData;
  });
};
