import * as moment from 'moment';
import * as actionTypes from '../constants';
import { DRGAnalyzeResultEntity, DrgAnalyzeState } from '../models/groupingEntity';

const createEmptyDrgAnalyzeState = (isLoading?: boolean): DrgAnalyzeState => ({
  loading: isLoading || false,
});

export const drgAnalyzeReducer = (state = createEmptyDrgAnalyzeState(), action) => {
  switch (action.type) {
    case actionTypes.OPEN_ENCOUNTER_BY_ID_COMPLETED:
    case actionTypes.CREATE_NEW_ENCOUNTER:
      return handleOpenEncounterByIdCompleted();
    case actionTypes.DRG_ANALYZE_BEGIN:
      return handleDrgAnalyzeBegin(state);
    case actionTypes.DRG_ANALYZE_ERROR:
      return handleDrgAnalyzeError(state);
    case actionTypes.DRG_ANALYZE_COMPLETED:
      return handleDrgAnalyzeCompleted(state, action.payload);
    case actionTypes.DRG_NEED_ANALYZE:
      return handleDrgAnalyzeSetNeedAnalyzeFlag(state, action.payload);
    default:
      return state;
  }
};

const handleOpenEncounterByIdCompleted = (): DrgAnalyzeState => {
  return createEmptyDrgAnalyzeState();
};

const handleDrgAnalyzeBegin = (state: DrgAnalyzeState): DrgAnalyzeState => {
  return {
    ...state,
    loadedAt: undefined,
    loading: true,
    result: undefined,
  };
};

const handleDrgAnalyzeError = (state: DrgAnalyzeState): DrgAnalyzeState => {
  // FIXME: create error info object?
  return {
    ...state,
    loadedAt: undefined,
    loading: false,
    result: undefined,
    needAnalyzing: false,
  };
};

const handleDrgAnalyzeCompleted = (state: DrgAnalyzeState, payload: DRGAnalyzeResultEntity[]): DrgAnalyzeState => {
  return {
    ...state,
    loadedAt: moment.now().toString(),
    loading: false,
    result: payload,
    needAnalyzing: false,
  };
};

const handleDrgAnalyzeSetNeedAnalyzeFlag = (state: DrgAnalyzeState, need: boolean): DrgAnalyzeState => {
  return {
    ...state,
   needAnalyzing: need,
  };
};
