import * as moment from 'moment';
import * as actionTypes from '../../../constants';
import { EncounterEntity } from '../../../models';
// import { UserPreferencesEntity } from '../../../models';
import { PartialChoiceLists } from '../../../models/patientEncounter';
import { ErrorDetails } from '../../../models/ui';
import { encounterAPI } from '../../../services/encounter';
import { parseServiceErrors } from '../../../services/encounter/serviceErrors';
import { applyFacilityData, getFacilityData } from './facilityChange';
import { FacilityData } from '../../../models/facilitySettings';
import { getEncounterAdditionalLists } from './encounterAdditionalLists';
import { prepareToOpenEncounter } from './prepareToOpenEncounter';
import { CreateEncounterState } from '../../../models/createEncounter';
import { mapChoiceListsToObject } from '../../../reducers/choiceLists';
import { getRecoveredEncountersCountAction } from '../../RecoveredEncounters/actions/recoveredEncountersAction';

export const createEncounterAction = (newEncounterData: CreateEncounterState, codeMetadataInitialized: boolean) => (dispatch) => {
  dispatch(createEncounterBegin());

  return prepareToOpenEncounter(dispatch, codeMetadataInitialized)
    .then((facilities) => {
      return encounterAPI
        .createEncounter(newEncounterData)
        .then((createResult) => {
          const { facility, type } = createResult;
          dispatch(getRecoveredEncountersCountAction());

          if (!facility) {
          // We have not selected facility - show error
            const errors =  [{ error: "No Facility", description: "No Facility", time: moment.now() }];
            dispatch(createEncounterError(errors));

            // dispatch(createEncounterCompleted(createResult, newEncounterData.choiceLists));
            return false;
          }

          const interfaceMode = createResult.IsInterfaced || false;
          // eslint-disable-next-line consistent-return
          return getFacilityData(dispatch, facility, type, interfaceMode, facilities, createResult)
            .then((facilityData: FacilityData) => {
              if (!applyFacilityData(dispatch, facility, facilityData)) {
                dispatch(createEncounterError([]));
                return false;
              }

              if (facilityData) {

                /*
                if(facilityData.preferences && facilityData.preferences.OutpatientPreferences) {
                  const billType = facilityData.preferences.OutpatientPreferences?.DefaultBillType || '';

                  // Set default bill type if available
                  createResult.billType = billType;
                }
                */

                if (facilityData.partialChoiceLists && facilityData.choiceListsData) {
                  const choiceListsData = {};
                  mapChoiceListsToObject(choiceListsData, facilityData.choiceListsData);
                  dispatch(createEncounterCompleted(createResult, choiceListsData, facilityData.partialChoiceLists));

                  return true;
                }
              }

              dispatch(createEncounterError([]));
              return false;
            });
        })
      //     .catch((reason) => {
      //       const errors = parseServiceErrors(reason, 'Cannot Get User Preference');
      //       dispatch(getEncounterPreferencesError(errors));
      //     });
      // })
        .then((result) => {
          if (!result) {
            return false;
          }

          return getEncounterAdditionalLists(dispatch)
            .then(() => {
              return true;
            })
        })
    })
    .catch((reason) => {
      const errors = parseServiceErrors(reason, 'Cannot Create Encounter');
      dispatch(createEncounterError(errors));

      return false;
    });
};

const createEncounterBegin = () => ({
  payload: '',
  type: actionTypes.CREATE_ENCOUNTER_BEGIN,
});

const createEncounterCompleted = (encounterEntity: EncounterEntity, choiceListsData, partialChoiceLists: PartialChoiceLists) => ({
  // always open Patient Encounter tab for the new encounter
  payload: { encounterEntity, choiceLists: choiceListsData, partialChoiceLists, openEncounterToCodingTab: false },
  type: actionTypes.CREATE_ENCOUNTER_COMPLETED,
});

const createEncounterError = (errors: ErrorDetails[]) => ({
  payload: errors,
  type: actionTypes.CREATE_ENCOUNTER_ERROR,
});
