import './Panel.less';

import * as React from 'react';
import { Icon, IconType } from '..';

interface PanelProps {
  className: string;
  title: string;
  children: React.ReactNode;
  icon?: IconType;
  iconAction?: () => void;
  buttonTooltip?: string;
  disabled?: boolean;
}

export const Panel = (props: PanelProps) => {
  return (
    <div className={`col ${props.className}`}>
      {props.disabled && <div className='custom-synonyms-sub-panel-disabled-area'/>}
      <div className="panel-title">
        {props.title}
        {props.icon && props.iconAction && (
          <button
            className="k-button k-icon-button"
            onClick={props.iconAction}
            type="button"
            title={props.buttonTooltip || ''}
          >
            <Icon type={props.icon} className="panel-icon" />
          </button>
        )}
      </div>
      <div className="panel-content">{props.children}</div>
    </div>
  );
};
